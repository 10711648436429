import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ProductsService } from 'src/app/services/products.service';
import { ZenduOne } from 'src/typings/app';
import { environment } from "../../../environments/environment";
import { CustomerService } from "../../services/customer.service";

@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {

  /**
   * installed products
   */
  public ssoProducts: ZenduOne.Product[] = [];

  /**
   * Geotab is available
   */
  public isGeotabAvail = false;

  constructor(
    private _auth: AuthService,
    private _productsService: ProductsService,
    private _customerService: CustomerService,
    private _notify: NotifierService
  ) { }

  ngOnInit() {
    this.init();
  }

  /**
   * Load installed application for current user
   */
  private async init() {
    try {
      // load apps that installed and supported Single-Sign-On
      await this.loadSSOProducts();

      // check geotab access
      await this.checkGeotabAccess();

    } catch (err) {
      this._notify.error(err);
    }
  }

  /**
   * check geotab access
   */
  private async checkGeotabAccess() {
    if(!this._auth.isSigned()){
      this.isGeotabAvail = false;
      return;
    }
    // load customer
    const customer = await this._customerService.findOne();
    // check geotab access by available platforms
    if (customer.platforms &&
      customer.platforms.find(c => c.id == "geotab")) {
      this.isGeotabAvail = true;
    }
  }

  /**
   * Load apps that installed and supported Single-Sign-On
   */
  private async loadSSOProducts() {
    this.ssoProducts = [];

    if (!this._auth.isSigned()) {
      return;
    }

    const username = this._auth.getUsername();
    this.ssoProducts = await this._productsService.findInstalled({
      username: username
    });
  }

  /**
   * redirect to geotab
   */
  public signInGeotab() {
    window.open(`${environment.service}/saml/geotab/redirect?token=${this._auth.getToken()}`, "_blank")
  }

}
