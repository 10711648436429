import { NotifierService } from 'src/app/services/notifier.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MemoryService } from 'src/app/services/memory.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-initalize',
  templateUrl: './initalize.component.html',
  styleUrls: ['./initalize.component.scss']
})
export class InitalizeComponent implements OnInit {

  constructor(
    private _router: Router,
    private _activeRouter: ActivatedRoute,
    private _notifier: NotifierService,
    private _authService: AuthService,
    private _memoryService: MemoryService,
    private _themeService: ThemeService) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      await this._authService.initAuth();

      if (!this._authService.isSigned()) {
        // navigate to home page
        this._router.navigate(['/sign-in']);
        return;
      }

      let query = this._activeRouter.snapshot.queryParams;
      let backurl = query.backurl;

      if (query.code) {
        // save reseller name for given session
        // this reseller name can be used for a new customer registration
        this._memoryService.setValue("reseller", query.code);
      }

      // load customization
      await this._themeService.loadCustomization(query.code || query.reseller || "");

      if (backurl && backurl != "/") {
        // clone properties to remove backurl argument and empty argument
        let copyOptions = Object.assign({}, query);
        delete copyOptions["backurl"];
        delete copyOptions[""];

        // navigate to backurl page
        this._router.navigate([backurl], { queryParams: copyOptions });
      }
      else {
        // navigate to default page
        this._router.navigate(['/portal/home']);
      }
    }
    catch (err) {
      this._notifier.error(err);
    }
  }

}
