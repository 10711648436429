import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  public isLoading: boolean;
  constructor(public dialogRef: MatDialogRef<ForgotComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _notifier: NotifierService) { 
                this.isLoading = false;
              }

  ngOnInit() {
  }

  public send(data){
    let email = data.value.email;
    if(!email.includes('@')){
      this._notifier.error("Invalid email");
      return;
    }
    if(((email.match(/@/g)||[])).length  != 1){
      this._notifier.error("Invalid email");
      return;
    }
    this.dialogRef.close({email:data.value.email});
  }
}
