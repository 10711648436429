import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MemoryService } from 'src/app/services/memory.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss']
})
export class AppNavbarComponent implements OnInit {

  @Input() loginVisible: boolean;

  public username: string;

  public isSigned: boolean;

  public isGeotabSession: boolean;

  public isMenuCollapsed: boolean;

  public isSidebarVisible = false;

  constructor(
    private _auth: AuthService, 
    private _router: Router,
    private _memoryService: MemoryService
  ) {
    this.loginVisible = true;
    this.username = this._auth.getUsername();
    this.isSigned = this._auth.isSigned();

    this.isMenuCollapsed = false;
    this.isGeotabSession = this._memoryService.getValue("active_session") == "geotab";
  }

  ngOnInit() {
  }

  public signIn() {
    this._router.navigate(["/sign-in"])
  }

  public signInGeotab() {
    this._router.navigate(["/geotab-signin"])
  }


  /**
   * toggle sidebar visibility
   */
  public toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  /**
   * hide sidebar
   */
  public hideSidebar(){
    this.isSidebarVisible = false;
  }
}
