import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { SupportService } from 'src/app/services/support.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  public isLoading: boolean;

  public tickets: Ticket[];

  public isSigned: boolean;

  constructor(
    private _supportService: SupportService,
    private _notify: NotifierService,
    private _authService: AuthService) {
    // signed
    this.isSigned = this._authService.isSigned();
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {

      if (!this.isSigned) {
        return;
      }

      this.isLoading = true;
      this.tickets = await this._supportService.findTickets();
      // show tickets started with Zenduone
      this.tickets = this.tickets.filter(t => t.subject && t.subject.startsWith("Zenduone"))
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
