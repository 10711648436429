import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit {

  public isLoading = false;

  constructor(
    private _customerService: CustomerService,
    private _router: Router,
    private _notify: NotifierService
  ) { }

  ngOnInit() {
  }

  public async accept() {
    try {
      this.isLoading = true;

      await this._customerService.acceptAgreement();

      // navigate to home page
      this._router.navigate(['/portal/home']);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }
}
