import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-popup-video',
  templateUrl: './popup-video.component.html',
  styleUrls: ['./popup-video.component.scss']
})
export class PopupVideoComponent implements OnInit {

  constructor(private _sanitizationService: DomSanitizer) { }

  @Input() public isVisible = true;

  @Input() public videoUrl: string;

  @Output() onClose: EventEmitter<string> = new EventEmitter();

  public isYoutube = false;

  public safeUrl: SafeResourceUrl;

  ngOnInit() {
    const reg =  /http[s]?\:\/\/(?:www\.)?youtube.com/;
    this.isYoutube = reg.test(this.videoUrl);
    this.safeUrl = this._sanitizationService.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

  public close(): void {
    this.isVisible = false;
    this.onClose.emit();
  }

}
