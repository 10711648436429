import { Component } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { ZenduOne } from 'src/typings/app';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthService } from 'src/app/services/auth.service';
import { MemoryService } from 'src/app/services/memory.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { QueryService } from 'src/app/services/query.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  public customer: ZenduOne.Customer;

  public isLoading: boolean;

  public products: Array<ZenduOne.Product>;

  public shippingSameAsBilling: boolean;

  public createZohoAccount: boolean;

  public countries: Array<{ id: string, name: string }>

  public months: Array<number>;

  public years: Array<number>;

  public iGeotabAvail: boolean;

  public isGeotabSessionAvail: boolean;

  private _geotabSession: ZenduOne.PlatformPayload;

  public geotab: ZenduOne.PlatformPayload

  private _creditCardToken: string;

  private _productId: string;

  constructor(
    private _authService: AuthService,
    private _memoryService: MemoryService,
    private _notify: NotifierService,
    private _customerService: CustomerService,
    private _cookies: CookieService,
    private _queryService:QueryService) {

    this.geotab = {};

    this.countries = [
      { id: "USA", name: "USA" },
      { id: "Canada", name: "Canada" }
    ]
    let opts = this._queryService.getOptions();

    this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    this.years = [];
    for (let idx = 0; idx <= 30; idx++) {
      this.years.push(new Date().getFullYear() + idx);
    }
    if(opts.pid){
      this._productId = opts.pid;
    }
    this.customer = {
      _id: opts.cid,
      name: opts.customerName ? opts.customerName : "",
      email: "",
      billingAddress: {
        country: "US"
      },
      shippingAddress: {
        country: "US"
      },
      phone: "",
      platforms: opts.platform ? [{id:opts.platform, required:false}] : []
    };
    this.products = [];

    const geotabSessionVal = this._cookies.get("geotab_session");
    if (geotabSessionVal) {
      this._geotabSession = JSON.parse(geotabSessionVal);

      this.isGeotabSessionAvail = true;

      this.customer.name = this._geotabSession.database;
      this.customer.email = this._geotabSession.username;
    }
  }


  public async confirm() {
    try {

      this.validate();

      this.isLoading = true;

      let resellerCode = this._memoryService.getValue("reseller");
      let platforms = new Array<ZenduOne.PlatformOptions>();
      if(this.customer._id){
        platforms.push({
          payload:{},
          platform:{
            id:this.customer.platforms[0].id,
            database:this.getDatabaseName()
          }
        })
      }
      if (this._geotabSession) {
        // add geotab platform
        platforms.push({
          payload: this._geotabSession,
          platform: {
            id: "geotab",
            database: this._geotabSession.database
          }
        })
      }
      else if (this.iGeotabAvail) {
        // add geotab platform
        platforms.push({
          payload: this.geotab,
          platform: {
            id: "geotab",
            database: this.geotab.database
          }
        })
      }
      else {
        // add standalone platform when geotab is not available
        platforms.push({
          payload: {
            database: this.getDatabaseName()
          },
          platform: {
            id: "standalone",
            database: this.getDatabaseName()
          }
        })
      }

      let data = await this._customerService.add({
        customer: this.customer,
        creditCardToken: this._creditCardToken,
        resellerCode: resellerCode,
        platforms: platforms
      });

      if (data.tokenId) {
        this._authService.saveToken(data.tokenId);

        await this._authService.initAuth();
      }

      this._notify.success("You successfully complete registration");

      setTimeout(() => {
        if(this._productId){
          document.location.href = `/portal/product/overview?id=${this._productId}`;
        }else{
          // hard redirect to refresh app
          document.location.href = `/portal/home?code=${resellerCode}`;
        }
      }, 1000);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private getDatabaseName() {
    let dbName = this.customer.name.replace(/[^a-zA-Z ]/g, "").toLowerCase();
    if (!dbName) {
      throw "Can't generate the database name from company name"
    }
    return dbName;
  }

  private validate() {
    if (!this.customer.name) {
      throw "company name is empy"
    }

    if (this.customer.name.length < 3) {
      throw "company name is too small"
    }

    if (!this.customer.email) {
      throw "email is empy"
    }

    this.validateAddress(this.customer.billingAddress);

    if (this.shippingSameAsBilling) {
      // clone billing address to shipping
      this.customer.shippingAddress = Object.assign({}, this.customer.billingAddress);
    }
    else {
      // validate shipping information
      this.validateAddress(this.customer.shippingAddress);
    }

    // validate geotab
    if (this.iGeotabAvail) {
      if (!this.geotab.database) {
        throw "geotab database is empty"
      }
      if (!this.geotab.username) {
        throw "geotab username is empty"
      }
      if (!this.geotab.password) {
        throw "geotab password is empty"
      }
    }
  }

  private validateAddress(addr: ZenduOne.Address) {
    if (!addr.street) {
      throw "street is empty"
    }

    if (!addr.state) {
      throw "state is empty"
    }

    if (!addr.country) {
      throw "country is empty"
    }

    if (!addr.city) {
      throw "city is empty"
    }

    if (!addr.code) {
      throw "code is empty"
    }

  }
}
