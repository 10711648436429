import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";
import { Middleware } from "../../typings/middleware";

@Injectable()
export class UserService {
    constructor(private _httpService: HttpService) { }

    /**
     * Get the current user
     */
    public async getCurrent(): Promise<ZenduOne.User> {
        return await this._httpService.post("marketplace/user/current");
    }

    /**
     * Update user properties
     */
    public async update(user: ZenduOne.User) {
        await this._httpService.post("marketplace/user/update", user);
    }

    /**
     * Reset user password
     */
    public async resetPassword() {
        await this._httpService.post("marketplace/user/resetPassword");
    }

    /**
     * Get Customer users
     */
    public async findCustomerUsers(search: Middleware.UserSearch): Promise<Array<Middleware.User>> {
        let items = await this._httpService.post("marketplace/users/find", search);
        return items;
    }

    /**
     * Update Customer user
     */
    public async updateCustomerUser(user: Middleware.User): Promise<Array<Middleware.User>> {
        let items = await this._httpService.post("marketplace/users/update", user);
        return items;
    }

    /**
     * Add Customer user
     */
    public async addCustomerUser(user: Middleware.User): Promise<Array<Middleware.User>> {
        let items = await this._httpService.post("marketplace/users/add", user);
        return items;
    }

    /**
     * Delete Customer user
     */
    public async removeCustomerUser(userId: string): Promise<Array<Middleware.User>> {
        let items = await this._httpService.post("marketplace/users/remove", { userId });
        return items;
    }

    /**
     * Delete Customer user
     */
    public async syncGeotabUsers(): Promise<void> {
        await this._httpService.post("marketplace/users/sync/geotab");
    }
    
}
