import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SupportService } from 'src/app/services/support.service';

@Component({
  selector: 'app-ticket-thread',
  templateUrl: './ticket-thread.component.html',
  styleUrls: ['./ticket-thread.component.scss']
})
export class TicketThreadComponent implements OnInit {

  public isLoading: boolean;

  public ticket: Ticket;

  public threads: TicketThread[];

  public replyText: string;

  public mailToLink: string;

  constructor(
    private _notify: NotifierService,
    private _activedRoute: ActivatedRoute,
    private _supportService: SupportService,
    private _router: Router) {
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;
      let ticketId = this._activedRoute.snapshot.queryParams.id;
      let tickets = await this._supportService.findTickets();
      this.ticket = tickets.find(t => t.id == ticketId);

      this.mailToLink = `mailto:support@zenduit.com?subject=[## ${this.ticket.ticketNumber} ##] Re: ${this.ticket.subject}`

      this.threads = await this._supportService.findThreads(ticketId);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async submit() {
    try {
      this.isLoading = true;

      if (this.ticket.id) {
        await this._supportService.updateTicket(this.ticket);

        this._notify.success("Ticket successfully updated");
      }
      else {
        await this._supportService.createTicket(this.ticket);

        this._notify.success("Ticket successfully created");
      }

      this._router.navigate(["/product/support"])
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async reply() {
    try {
      this.isLoading = true;

      if (!this.ticket.id) {
        return;
      }

      if (!this.replyText) {
        throw "please input reply text"
      }

      await this._supportService.sendReply({
        ticketId: this.ticket.id,
        text: this.replyText
      });
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }
}
