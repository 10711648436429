import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable()
export class HttpService {
    constructor(
        private _auth: AuthService,
        private _http: HttpClient) { }

    public async post(url: string, data?: any) {

        const httpOptions = {
            headers: new HttpHeaders({
                "Authorization": this._auth.getToken()
            })
          };

        let res = await this._http.post(`${environment.service}/${url}`,
            data ? data : {},
            httpOptions).toPromise()
        return res as any;

    }
}