import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Middleware } from "../../../../../typings/middleware";
import { NotifierService } from "../../../../services/notifier.service";
import { CancelConfirmComponent } from "../../../../dialogs/cancel-confirm/cancel-confirm.component";
import { MatDialog } from "@angular/material";
import { DeleteUserComponent } from "../../../../dialogs/delete-user/delete-user.component";


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent  {

  @Input() user: Middleware.User;

  @Output() close = new EventEmitter<Middleware.User>();

  @Output() deleteUser = new EventEmitter<void>();

  @Output() cancel = new EventEmitter<void>();

  public isEditStarted = false;

  public securityTypes = [
    { value: 'admin', label: 'Admin'},
    { value: 'user', label: 'User'},
    { value: 'viewer', label: 'Viewer'},
    { value: 'userNoLiveViewer', label: 'UserNoLiveViewer'},
    { value: 'noAccess', label: 'NoAccess'},
  ]

  constructor(private _notify: NotifierService,
              private _dialog: MatDialog,) {}

  public startEdit() {
    this.isEditStarted = true
  }

  public discard() {
    this.cancel.emit();
  }

  public async delete() {
    try {
      const dialogRef = this._dialog.open(DeleteUserComponent, { width: '600px' });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (!confirm) {
        return;
      }

      this.deleteUser.emit();
    } catch (err) {
      this._notify.error(err);
    }
  }

  private validate() {
    if (!this.user.name) {
      this._notify.error('User Email Field is required');
      return false;
    }

    if(!this.validateEmail(this.user.name)){
      this._notify.error('User Email Field is not valid');
      return false;
    }

    if (!this.user.firstName) {
      this._notify.error('User First Name Field is required');
      return false;
    }

    if (!this.user.lastName) {
      this._notify.error('User Last Name Field is required');
      return false;
    }

    if (!this.user.type) {
      this._notify.error('User Security Field is required');
      return false;
    }

    return true
  }

  private validateEmail(email: string){
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public save() {
    if (!this.validate()) {
      return;
    }

    // close dialog
    this.close.emit(this.user);
  }
}
