import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ZenduOne } from 'src/typings/app';

@Component({
  selector: 'app-fullscreen-preview',
  templateUrl: './fullscreen-preview.component.html',
  styleUrls: ['./fullscreen-preview.component.scss']
})
export class FullscreenPreviewComponent implements OnInit {

  public selectedItem: ZenduOne.ProductPreview;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      items: ZenduOne.ProductPreview[],
      selectedIndex: number
    }
  ) {
    this.selectedItem = data.items[data.selectedIndex];
  }

  ngOnInit() {
  }

  public next() {
    this.data.selectedIndex = Math.min(this.data.selectedIndex + 1, this.data.items.length - 1);
    this.selectedItem = this.data.items[this.data.selectedIndex];
  }

  public prev() {
    this.data.selectedIndex = Math.max(this.data.selectedIndex - 1, 0);
    this.selectedItem = this.data.items[this.data.selectedIndex];
  }

}
