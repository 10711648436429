import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BillingService } from 'src/app/services/billing.service';
import { RouteBillingProduct } from 'src/typings/billing';
import { MarketplaceFilter } from '../marketplace/marketplace-filter/marketplace-filter';

interface NavigationGroup {
  name: string,
  viewProducts: ZenduOne.Product[],
  products: ZenduOne.Product[],
  isAllVisible: boolean
}

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: [
    './products-list.component.scss',
    './products-list.ghost.scss'
  ]
})
export class ProductsListComponent implements OnInit {

  public isLoading: boolean;

  public billings: { [key: string]: RouteBillingProduct } = {};

  /**
   * Filter menu visibility
   */
  public isFilterVisible = false;

  /**
   * Products grouped by type
   */
  public groups: NavigationGroup[] = [];

  /**
   * Filter 
   */
  public filter: MarketplaceFilter = {
    text: "",
    categories: [],
    types: {
      free: true
    }
  };

  private _products: ZenduOne.Product[] = [];

  private _abort: Abort;

  constructor(
    private _productsService: ProductsService,
    private _notify: NotifierService,
    private _activeRoute: ActivatedRoute,
    private _billingService: BillingService,
    private _authService: AuthService
  ) {
  }

  ngOnInit() {

    let timer;

    this._abort = { isAborted: false }

    this._activeRoute
      .queryParams
      .subscribe(() => {
        clearTimeout(timer);

        timer = setTimeout(() => {
          if (this._abort) {
            this._abort.isAborted = true;
          }

          this._abort = { isAborted: false }
          this.update(this._abort);
        }, 100);

      });

    this.update(this._abort);
  }

  private async update(abort: Abort) {
    try {
      this.isLoading = true;

      let query = this._activeRoute.snapshot.queryParams;

      this.filter.text = query.searchName || "";

      if (this._authService.getUsername()) {
        // user is authenticated, load available billings
        let billing = await this._billingService.findOne();
        if (billing && billing.products) {
          for (let product of billing.products) {
            this.billings[product.id] = product;
          }
        }
      }

      this._products = await this._productsService.find({});
      if (abort.isAborted) {
        return;
      }

      this.filterGroups();

      this.isLoading = false;
    }
    catch (err) {
      this._notify.error(err);
      this.isLoading = false;
    }
  }

  /**
   * Filter and split products by groups (All solution, Popular, Free, ...)
   */
  private filterGroups() {
    this.groups = [];

    // filter products
    //
    const filteredProducts = this._products.filter(p => {

      if (this.filter.text) {
        // filter by text
        if (!p.name.toLowerCase().includes(this.filter.text.toLowerCase())) {
          return false;
        }
      }

      // filter by categories
      //
      if (this.filter.categories &&
        this.filter.categories.length) {
        let isIncluded = false;
        for (const c of this.filter.categories) {
          if (p.content.categories.includes(c)) {
            isIncluded = true;
            break;
          }
        }
        if (!isIncluded) {
          return false;
        }
      }

      // filter by free
      if (!this.filter.types.free) {
        if (this.isFree(p)) {
          return false;
        }
      }

      return true;
    })

    console.log(filteredProducts);

    // Create groups
    //

    if (this.filter.text) {
      // create the one product group
      this.groups.push(this.createNavigationGroup(`Search result for "${this.filter.text}"`, filteredProducts));
    }
    else {
      // add all solutions
      this.groups.push(this.createNavigationGroup("All solutions", filteredProducts, true));

      // add popular solutions
      let popularProducts = filteredProducts.filter(p => p.content && p.content.tags &&
        (this.isPopular(p)));
      if (popularProducts.length) {
        // place most popular to first place
        this.sortPopularProducts(popularProducts);

        this.groups.push(this.createNavigationGroup("Popular Solutions", popularProducts, true));
      }

      // add free solutions
      let freeProducts = filteredProducts.filter(p => this.isFree(p));
      if (freeProducts.length) {
        this.groups.push(this.createNavigationGroup("Free Solutions", freeProducts, true));
      }
    }
  }

  /**
   * Is free solution
   */
  private isFree(p: ZenduOne.Product): boolean {
    return p.content &&
      p.content.tags &&
      p.content.tags.includes("Free");
  }

  /**
   * Is popular solution
   */
  private isPopular(p: ZenduOne.Product): boolean {
    return p.content &&
      p.content.tags &&
      (p.content.tags.includes("Popular") || p.content.tags.includes("Most Popular"));
  }

  private createNavigationGroup(name: string, products: ZenduOne.Product[], multiple?: boolean): NavigationGroup {
    return {
      name: name,
      products: products,
      viewProducts: multiple || this.groups.length > 1 ? products.slice(0, 3) : products,
      isAllVisible: products.length > 3
    }
  }

  /**
   * Show all products in group
   * @param group 
   */
  public showAll(group: NavigationGroup) {
    group.viewProducts = group.products.slice(0);
    // hide See all button
    group.isAllVisible = false;
  }

  private sortPopularProducts(popularProducts: ZenduOne.Product[]) {
    // place most popular to first place
    popularProducts.sort((p1, p2) => {
      let isMostPopular1 = p1.content.tags.includes("Most Popular");
      let isMostPopular2 = p2.content.tags.includes("Most Popular");
      if (isMostPopular1 && isMostPopular2) {
        return p1.name.localeCompare(p2.name);
      }
      else if (isMostPopular1) {
        return -1;
      }
      else if (isMostPopular2) {
        return 1;
      }
      else {
        return p1.name.localeCompare(p2.name);
      }
    });
  }

  /**
   * Hide the filter menu
   */
  public hideSidebar() {
    this.isFilterVisible = false;
  }

  /**
   * Show the filter menu
   */
  public showSidebar() {
    this.isFilterVisible = !this.isFilterVisible;
  }

  /**
   * Filter products and close the menu
   */
  public filterAndClose() {
    this.filterGroups();
    this.isFilterVisible = false;
  }
}
