import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-starter',
  templateUrl: './home-starter.component.html',
  styleUrls: ['./home-starter.component.scss']
})
export class HomeStarterComponent implements OnInit {

  /**
   * Categories
   */
  public categories: Array<{ title: string, id: string }> = [];

  /**
   * Category items
   */
  public items: Array<{ preview: string, title: string, duration: number }> = [];

  constructor() {
    this.createCategories();

    this.createItems();
  }

  private createCategories() {
    this.categories = [
      { id: "basics", title: "Basics" },
      { id: "geotab", title: "Login to Geotab" },
      { id: "integration", title: "How to Integrate telematics" }
    ];
  }

  private createItems() {
    this.items = [
      {
        preview: "/assets/img/home-starter/preview1.png",
        title: "Getting started with Zenduone service",
        duration: 3
      },
      {
        preview: "/assets/img/home-starter/preview2.png",
        title: "What is ZenduOne",
        duration: 6
      },
      {
        preview: "/assets/img/home-starter/preview2.png",
        title: "Quick start getting around ZenduOne",
        duration: 9
      }
    ];
  }

  ngOnInit() {
  }

}
