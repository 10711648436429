import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZenduOne } from 'src/typings/app';
import { CustomerService } from 'src/app/services/customer.service';
import { ProductsService } from 'src/app/services/products.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { Middleware } from 'src/typings/middleware';
import { BillingService } from 'src/app/services/billing.service';
import { RouteBillingProduct } from 'src/typings/billing';
import { UserType } from 'src/typings/userType';
import { CategoryService } from 'src/app/services/category.service';

type ProductInstallState = "plan" | "vehicles" | "users" | "finalize";

@Component({
  selector: 'app-product-install',
  templateUrl: './product-install.component.html',
  styleUrls: [
    './product-install.component.scss',
    './product-install.ghost.scss'
  ]
})
export class ProductInstallComponent implements OnInit {

  public isLoading: boolean;

  public isInstalling: boolean;

  public isInstalled: boolean;

  public isActive: boolean;

  public customer: ZenduOne.Customer;

  public product: ZenduOne.Product;

  public category: ZenduOne.Category;

  public vehicles: Array<{
    id: string,
    name: string,
    telematic?: ZenduOne.VehicleTelematic,
    media?: ZenduOne.VehicleMedia,
    selected: boolean
  }>;

  public users: Array<{
    name: string,
    fullName: string,
    type: string,
    selected: boolean
  }>;

  public customUsername: string;

  public dataSourceVehicle = new MatTableDataSource();

  public dataSourceUsers = new MatTableDataSource();

  public billingPlan: string;

  public state: ProductInstallState;

  public states: ProductInstallState[];

  public isAgreementAccepted = false;

  public agreementFlag = false;

  public selectedVehicles: Middleware.ConfigureVehicle[];

  public selectedUsers: Middleware.ConfigureUser[];

  @ViewChild("paginatorusers") paginatorUsers: MatPaginator;

  @ViewChild("paginatorvehicles") paginatorVehicles: MatPaginator;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _productService: ProductsService,
    private _notify: NotifierService,
    private _vehicleService: VehicleService,
    private _router: Router,
    private _billingService: BillingService,
    private _categoryService: CategoryService
  ) {
  }

  ngOnInit() {
    this.update();
  }


  private async update() {
    try {
      this.isLoading = true;

      let query = this._activeRoute.snapshot.queryParams;

      this.customer = await this._customerService.findOne();
      if (!this.customer) {
        throw `invalid customer`
      }

      let productId = query.id;
      if (!productId) {
        throw `invalid product id "${productId}"`
      }

      this.product = await this._productService.findOne(productId);
      if (!this.product) {
        throw `invalid product id "${productId}"`
      }

      if (this.product.content.categories &&
        this.product.content.categories.length) {
        let categoryId = this.product.content.categories[0];
        this.category = await this._categoryService.findOne(categoryId)
      }

      // initalize states
      this.initStates(this.product);

      let billing = await this._billingService.findOne();

      // check installation state
      this.isInstalled = false;
      let customerProduct: RouteBillingProduct;
      if (billing && billing.products) {
        customerProduct = billing.products.find(p => p.id == this.product._id);
        if (customerProduct) {
          this.isInstalled = true;
          this.isActive = customerProduct.active;
          this.billingPlan = customerProduct.planId;
          this.isAgreementAccepted = true;
        }
      }

      if (!this.billingPlan) {
        // set default plan for single plan solution
        if (this.product.plans.length == 1) {
          this.billingPlan = this.product.plans[0].id;
        }
      }

      this.vehicles = [];
      this.users = [];

      if (this.isInstalled) {

        await this.loadUsers();

        await this.loadVehicles();
      }
      else {
        // load and add all vehicles
        let customerVehicles = await this._vehicleService.find();
        for (let customerVehicle of customerVehicles) {
          this.vehicles.push({
            id: customerVehicle._id,
            name: customerVehicle.name,
            telematic: customerVehicle.telematic,
            media: customerVehicle.media,
            selected: false
          });
        }
      }

      this.isLoading = false;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  /**
   * load vehicles for configuration
   */
  private async loadVehicles() {

    let productVehicles = new Array<Middleware.Device>();
    try {
      productVehicles = await this._customerService.getVehicles({ type: this.product.type });
    }
    catch (err) {
      this._notify.error(`Failed load installed vehicles for given application: ${err}`);
    }
    let customerVehicles = await this._vehicleService.find();
    this.vehicles = []

    let handledIds = [];
    // add product vehicle to top of list
    for (let productVehicle of productVehicles) {

      let isSuspend = false;
      if (productVehicle.activeTo &&
        new Date(productVehicle.activeTo) < new Date()) {
        isSuspend = true;
      }

      let customerVehicle = customerVehicles.find(v => v.telematic && v.telematic.serial == productVehicle.serialNumber);
      if (customerVehicle) {
        this.vehicles.push({
          id: customerVehicle._id,
          name: customerVehicle.name,
          telematic: customerVehicle.telematic,
          media: customerVehicle.media,
          selected: isSuspend ? false : true
        });
        handledIds.push(customerVehicle._id);
      }
    }

    // add other customers vehicles for installation
    //
    for (let customerVehicle of customerVehicles) {
      if (handledIds.indexOf(customerVehicle._id) >= 0) {
        continue;
      }
      this.vehicles.push({
        id: customerVehicle._id,
        name: customerVehicle.name,
        telematic: customerVehicle.telematic,
        media: customerVehicle.media,
        selected: false
      });
    }

    // move selected vehicles to top and sort by name
    //
    this.vehicles.sort((v1, v2) => {
      if (v1.selected && v2.selected) {
        return v1.name.localeCompare(v2.name);
      }
      if (v1.selected && !v2.selected) {
        return -1;
      }
      if (!v1.selected && v2.selected) {
        return 1;
      }
      return v1.name.localeCompare(v2.name);
    })
  }

  /**
   * load users for configuration
   */
  private async loadUsers() {
    let productUsers = new Array<Middleware.User>();
    try {
      productUsers = await this._customerService.getUsers({ type: this.product.type });
    }
    catch (err) {
      this._notify.error(`Failed load installed users for given application: ${err}`);
    }

    this.users = productUsers.map(u => ({
      name: u.name,
      type: u.type,
      fullName: `${u.firstName || ""} ${u.lastName || ""}`,
      selected: true
    }));
  }

  private initStates(product: ZenduOne.Product) {
    this.state = "plan";
    this.states = ["plan"];
    if (product.installation.vehicles.enabled) {
      this.states.push("vehicles");
    }
    if (product.installation.users.enabled) {
      this.states.push("users");
    }
    this.states.push("finalize");
  }

  public setState(state: ProductInstallState) {
    this.state = state;
    this.updateDataSources();
    if (state == "finalize") {
      // update the selected vehicles/users for finalize state
      this.selectedUsers = this.getConfigureUsers();
      this.selectedVehicles = this.getConfigureVehicles();
    }
  }

  /**
   * navigate to the next state
   */
  public nextState() {
    let idx = this.states.indexOf(this.state);
    let nextState = this.states[idx + 1];
    if (nextState) {
      this.setState(nextState);
    }
  }

  private updateDataSources() {
    // update datasource after timeout
    // paginators is not exists when component is not visible
    setTimeout(() => {
      // update table data sources
      this.dataSourceVehicle.data = this.vehicles;
      this.dataSourceVehicle.paginator = this.paginatorVehicles;

      this.dataSourceUsers.data = this.users;
      this.dataSourceUsers.paginator = this.paginatorUsers;
    }, 100)
  }

  /**
   * toggle selection for all users
   */
  public toggleUsers() {
    let state = true;
    if (this.users.filter(u => u.selected).length) {
      state = false;
    }
    for (let u of this.users) {
      u.selected = state;
    }

    this.dataSourceUsers.data = this.users;
  }

  /**
  * retrive selection flag when all vehicles seelected
  */
  public isAllUsersSelected() {
    if (!this.users || !this.users.length) {
      return false;
    }
    let len = this.users.filter(v => v.selected).length;
    return (len == this.users.length);
  }

  /**
   * toggle vehicle selection
   */
  public toggleVehicles() {
    let state = true;
    if (this.vehicles.filter(v => v.selected).length) {
      state = false;
    }
    for (let v of this.vehicles) {
      v.selected = state;
    }

    this.dataSourceVehicle.data = this.vehicles;
  }

  /**
   * retrive selection flag when all vehicles seelected
   */
  public isAllVehiclesSelected() {
    if (!this.vehicles || !this.vehicles.length) {
      return false;
    }
    let len = this.vehicles.filter(v => v.selected).length;
    return (len == this.vehicles.length);
  }

  /**
   * Add custom user to the users list
   */
  public addUser() {
    if (!this.customUsername) {
      return;
    }

    this.users.splice(0, 0, {
      name: this.customUsername,
      fullName: this.customUsername,
      type: UserType.admin,
      selected: true
    })

    this.customUsername = "";
    this.dataSourceUsers.data = this.users;
  }

  /**
   * run installation process
   */
  public async install() {
    try {

      let users = this.getConfigureUsers();
      if (this.product.installation.users.enabled &&
        !users.length) {
        throw "select one or more users";
      }

      let vehicles = this.getConfigureVehicles();
      if (this.product.installation.vehicles.enabled) {
        if (!vehicles.length) {
          throw "select one or more vehicles";
        }
      }

      this.isInstalling = true;

      await this._productService.install({
        productId: this.product._id,
        users: users,
        vehicles: vehicles,
        billingPlan: this.billingPlan
      })

      if (!this.isInstalled) {
        this._notify.success(`App "${this.product.name}" successfuly installed`);
        // navigate back to customer
        this._router.navigate(["/portal/home"], { queryParams: { wasInstalled: this.product._id } });
      }
      else {
        this.isActive = true;
        this._notify.success(`App "${this.product.name}" successfuly configured`);
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isInstalling = false;
    }
  }


  private getConfigureVehicles() {
    let vehicles = new Array<Middleware.ConfigureVehicle>();
    if (this.product.installation.vehicles.enabled) {
      vehicles = this.vehicles
        .filter(v => v.selected)
        .map(v => ({ id: v.id, telematic: v.telematic, media: v.media }));
    }
    return vehicles;
  }

  private getConfigureUsers() {
    let users = new Array<Middleware.ConfigureUser>();
    if (this.product.installation.users.enabled) {
      users = this.users.filter(u => u.selected)
        .map(u => ({ name: u.name, type: "admin", fullName: u.fullName }));
    }
    return users;
  }

  public applyUserFilter(filterValue: string) {
    this.dataSourceUsers.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceUsers.paginator) {
      this.dataSourceUsers.paginator.firstPage();
    }
  }

  /**
   * check vehicles selection
   */
  public isVehiclesSelected() {
    if (!this.vehicles) {
      return false;
    }
    return this.vehicles.find(v => v.selected) ? true : false;
  }

  /**
   * check users selection
   */
  public isUsersSelected() {
    if (!this.users) {
      return false;
    }
    return this.users.find(v => v.selected) ? true : false;
  }

  public acceptAgreement() {
    this.isAgreementAccepted = true;
  }

  private isSystemUser(username: string) {
    if (!username) {
      return false;
    }
    if (username == "support@gofleet.ca" ||
      username == "bi@gofleet.com") {
      return true;
    }

    if (username.includes("-service@zenduit.com")) {
      return true;
    }

    if (username.startsWith("service-") && username.includes("@zenduit.com")) {
      return true;
    }

    return false;
  }
}
