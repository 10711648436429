import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QueryService } from './services/query.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public themeCSS: string;

  constructor(
    private _router: Router,
    private _queryService: QueryService
  ) {
    // setup css theme
    let resellerCode = this.getResellerCode();
    this.themeCSS = `${environment.service}/marketplace/theme/getCSS?resellerCode=${resellerCode}`
  }

  ngOnInit() {

    const nonRedirects = [
      "/sign-in",
      "/oauth",
      "/register",
      "/geotab-signin",
      "/initalize",
      "/password-reset",
      "/eula",
      "/saml",
      "/marketplace"];

    if (document.location.pathname) {
      for (const redirect of nonRedirects) {
        if (document.location.pathname.indexOf(redirect) >= 0) {
          // no need redirect for given path
          return;
        }
      }

      let options = this._queryService.getOptions();
      options["backurl"] = document.location.pathname;
      this._router.navigate(["initalize"], { queryParams: options });
    }
  }


  private getResellerCode() {
    let options = this._queryService.getOptions();
    let resellerCode = options.code || options.reseller || "";
    return resellerCode;
  }

}
