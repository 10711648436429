import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SupportService } from 'src/app/services/support.service';
import { ZenduOne } from 'src/typings/app';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  public isLoading = false;

  public isUploading = false;

  public ticket: Ticket = {};

  public softwares = [
    "ZenduOne",
    "ZenduCAM",
    "ZenduMaintenance",
    "ZenduMaps",
    "Other"
  ];

  public types = [
    "Bug",
    "Reporting Issue",
    "Quality of Service",
    "Custom Report",
    "Feature Request",
    "Settings Change"
  ];

  public requestType = "";

  public software = "";

  public description = "";

  public link = "";

  public filename = "";

  constructor(
    private _notify: NotifierService,
    private _fileService: FileService,
    private _supportService: SupportService,
    private _router: Router) {
  }

  ngOnInit() {
  }


  public async submit() {
    try {
      this.isLoading = true;

      let ticket = Object.assign({}, this.ticket) as Ticket;

      if (!ticket.subject.startsWith("Zenduone")) {
        // add subject to indentify ticket in the future
        ticket.subject = "Zenduone: " + ticket.subject;
      }


      ticket.description = "";
      ticket.description += `Software: ${this.software} <br>`;
      ticket.description += `Request Type: ${this.requestType} <br>`;
      if (this.link) {
        ticket.description += `Attached file: ${this.link} <br>`;
      }
      ticket.description += `Described Issue: ${this.description} <br>`;

      await this._supportService.createTicket(ticket);

      this._notify.success("Ticket successfully created");

      this._router.navigate(["/portal/support"])
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public isDisabled() {
    return (!this.ticket.subject || !this.requestType || !this.software);
  }


  /**
   * handle logo selection
   */
  public async handleFileInput(files: FileList) {
    try {
      if (!files.length) {
        return;
      }

      this.filename = "...."

      const file = files.item(0);
      this.link = await this._fileService.upload(file);

      this.filename = file.name;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isUploading = false;
    }
  }

}
