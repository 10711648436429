import { Component, Input, OnChanges } from '@angular/core';
import { BillingService } from 'src/app/services/billing.service';
import { ZenduOne } from 'src/typings/app';
import { NotifierService } from 'src/app/services/notifier.service';
import { Middleware } from 'src/typings/middleware';
import { BillingCalcLine, BillingCalculateResult } from 'src/typings/billing';

@Component({
  selector: 'app-install-finalize',
  templateUrl: './install-finalize.component.html',
  styleUrls: ['./install-finalize.component.scss']
})
export class InstallFinalizeComponent implements OnChanges {

  @Input() customer: ZenduOne.Customer;

  @Input() plan: string;

  @Input() product: ZenduOne.Product;

  @Input() vehicles: Array<Middleware.ConfigureVehicle>;

  @Input() users: Array<Middleware.ConfigureUser>;

  public totalValue: string;

  public dueDate: Date = new Date();

  public billingLines: BillingCalcLine[] = [];

  public totalLbl: string;

  public isLoading: boolean;

  public planName: string;

  constructor(
    private _billingService: BillingService,
    private _notify: NotifierService
  ) { }

  public ngOnChanges() {
    this.calculatePricing();
  }

  private async calculatePricing() {
    try {
      this.isLoading = true;
      this.totalValue = "...";
      this.totalLbl = "...";

      if (!this.customer || !this.product || !this.plan) {
        // not enough data to calculate the pricing
        return;
      }

      let plan = this.product.plans.find(p => p.id == this.plan);
      this.planName = plan ? plan.name : "-";

      const billing = await this._billingService.caclulate({
        billingPlan: this.plan,
        features: [],
        productId: this.product._id,
        users: this.users || [],
        vehicles: this.vehicles || []
      });
      this.totalValue = `$${billing.total}`;
      this.billingLines = billing.lines;
      this.updateTotalLbl(billing);
    }
    catch (err) {
      this._notify.error(err);
      this.totalValue = "FAILED";
    }
    finally {
      this.isLoading = false;
    }
  }

  /**
   * update the total label on the summary table
   */
  private updateTotalLbl(billing: BillingCalculateResult) {
    if (!billing.total) {
      this.totalLbl = "$0";
      return;
    }

    this.totalLbl = "";
    for (let line of billing.lines) {
      if (this.totalLbl) {
        this.totalLbl += ` +`;
      }
      if (line.count == 1) {
        this.totalLbl += `$${line.price}`;
      }
      else if (line.count > 0) {
        this.totalLbl += `${line.count}x$${line.price}`;
      }
    }

    this.totalLbl += ` = $${billing.total}`
  }
}
