import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-main',
  templateUrl: './marketplace-main.component.html',
  styleUrls: ['./marketplace-main.component.scss']
})
export class MarketplaceMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
