import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { RouteBillingCalculateParam, BillingCalculateResult, RouteBillingCustomer } from "src/typings/billing";


@Injectable()
export class BillingService {
    constructor(private _httpService: HttpService) { }

    public async caclulate(options: RouteBillingCalculateParam): Promise<BillingCalculateResult> {
        let data = await this._httpService.post("marketplace/billing/calculate", options);
        return data;
    }

    public async findOne(): Promise<RouteBillingCustomer> {
        let data = await this._httpService.post("marketplace/billing/get");
        return data;
    }
}