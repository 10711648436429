import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/services/notifier.service';
import { MemoryService } from 'src/app/services/memory.service';

@Component({
  selector: 'app-geotab-signin',
  templateUrl: './geotab-signin.component.html',
  styleUrls: ['./geotab-signin.component.scss']
})
export class GeotabSigninComponent implements OnInit {

  public isLoading: boolean;

  public credentials: {
    username?: string,
    password?: string,
    database?: string,
    server?: string
  }

  constructor(
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _notify: NotifierService,
    private _memoryService: MemoryService
  ) { 
    this.credentials = {
      server: "my.geotab.com"
    }
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      const query = this._activatedRoute.snapshot.queryParams;
      if (query.database && query.username &&
        query.sessionId && query.server) {
        // auto login with geotab session
        await this.signInWithSession();
        return;
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  /**
   * Login with geotab session
   */
  private async signInWithSession() {
    const query = this._activatedRoute.snapshot.queryParams;

    await this._authService.signInWithGeotab({
      database: query.database,
      username: query.username,
      sessionId: query.sessionId,
      password: "",
      server: query.server,
    });

    if (query.reseller) {
      // save reseller name for given session
      // this reseller name can be used for a new customer registration
      this._memoryService.setValue("reseller", query.reseller);
    }

    this._memoryService.setValue("active_session", "geotab");

    const backurl = this._activatedRoute.snapshot.queryParams.backurl;
    if (backurl) {
      // redirect to backurl
      document.location.href = decodeURIComponent(backurl);
      return;
    }

    // redirect to home page
    this._router.navigate(["/product"]);
  }

  public async signIn() {
    try {
      this.isLoading = true;

      await this._authService.signInWithGeotab({
        database: this.credentials.database,
        username: this.credentials.username,
        password: this.credentials.password,
        server: this.credentials.server
      });
      this.redirectToSite();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private redirectToSite() {
    const query = this._activatedRoute.snapshot.queryParams;
    if (query["backurl"]) {
      // redirect to backurl
      document.location.href = decodeURIComponent(query["backurl"]);
      return;
    }

    this._router.navigate(["/product"]);
  }
}
