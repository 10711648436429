import { Pipe, PipeTransform } from '@angular/core';
import { VehicleType } from 'src/typings/vehiclesType';

@Pipe({
    name: 'labelVehicleType'
})
export class VechileTypeLabelPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (!value) {
            return "None";
        }
        switch (value) {
            case VehicleType.geotab:
                return "Geotab";
            case VehicleType.smartwitness:
                return "Smartwitness";
            case VehicleType.mobile:
                return "Mobile";
            case VehicleType.solar_xt:
                return "XT Solar Device";
            case VehicleType.bewhere:
                return "BeWhere";
            case VehicleType.globalstar:
                return "Globalstar";
            case VehicleType.lonedefender:
                return "Lone Defender";
            case VehicleType.custom:
                return "Custom Tracker";
            default:
                return value;
        }
    }

}
