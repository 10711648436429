import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition } from '@angular/material';

@Injectable()
export class NotifierService {
    constructor(private _snackBar: MatSnackBar) { }

    public info(msg: string, horzPos?: MatSnackBarHorizontalPosition, delay?: number) {
        // use setTimeout to fix lifecycle issue
        setTimeout(() => {
            this._snackBar.open(msg, "Close", {
                duration: delay ? delay : 5000,
                panelClass: "snack-info",
                verticalPosition: "top",
                horizontalPosition: horzPos ? horzPos : "center"
            });
        });
    }

    public success(msg: string) {
        // use setTimeout to fix lifecycle issue
        setTimeout(() => {
            this._snackBar.open(msg.toString(), "Close", {
                duration: 5000,
                verticalPosition: "top",
                panelClass: "snack-success"
            });
        });
    }

    public warn(msg: string) {
        // use setTimeout to fix lifecycle issue
        setTimeout(() => {
            this._snackBar.open(msg.toString(), "Close", {
                duration: 5000,
                verticalPosition: "top",
                panelClass: "snack-warn"
            });
        });
    }

    public error(err: string, delay?: number): any {
        console.error(err);
        // use setTimeout to fix lifecycle issue
        setTimeout(() => {
            let msg = this.getErrorMessage(err);
            this._snackBar.open(msg, "Close", {
                duration: delay || 5000,
                verticalPosition: "top",
                panelClass: "snack-error"
            });
        });
    }

    private getErrorMessage(ex) {
        if (!ex) {
            return "";
        }

        if (typeof ex == "string") {
            return ex;
        }
        else if (typeof ex.error == "string") {
            return ex.error;
        }
        else if (ex.message) {
            return ex.message;
        }
        else if (ex.status && ex.statusText) {
            return `Http error ${ex.status} ${ex.statusText}`;
        }
        else if (ex.statusText) {
            return ex.statusText;
        }
        else {
            console.warn("Can't extract error message from object:", ex);
            return "";
        }
    }
}

