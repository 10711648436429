import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'src/app/services/notifier.service';
import { ProductsService } from 'src/app/services/products.service';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ZenduOne } from 'src/typings/app';

@Component({
  selector: 'app-sso-product',
  templateUrl: './sso-product.component.html',
  styleUrls: ['./sso-product.component.scss']
})
export class SsoProductComponent implements AfterViewInit {

  @ViewChild('frame') frame: ElementRef;

  public isLoading: boolean;

  public geotabRequired: boolean;

  public activatedProduct: ZenduOne.Product;

  private _abort: { isAborted: boolean }

  private _activeProductType: string;

  constructor(
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
    private _notifier: NotifierService,
    private _authService: AuthService,
    private _productService: ProductsService,
    private _cookies: CookieService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.init();
    }, 100)
  }

  private init() {
    // initalize product
    this._abort = { isAborted: false };
    this.geotabRequired = false;
    this.loadProduct(this._abort);

    // attach to route changes
    //
    this._activatedRoute.params.subscribe((params) => {
      const productType = params["productType"];
      if (!productType || this._activeProductType == productType) {
        // no changes
        return;
      }
      // abort previous loading
      if (this._abort) {
        this._abort.isAborted = true;
      }
      this._abort = { isAborted: false };

      // load a new product
      this.loadProduct(this._abort);
    })
  }

  /**
   * load application in the iframe
   * @param abort object use for detection abort of operation
   */
  private async loadProduct(abort: { isAborted: boolean }) {
    try {
      this.isLoading = true;
      this.geotabRequired = false;

      if (!this._authService.isSigned()) {
        throw "Please sig-in"
      }

      const productType = this._activatedRoute.snapshot.params.productType;
      if (!productType) {
        throw "product type not passed"
      }
      this._activeProductType = productType;

      if (abort.isAborted) {
        // process aborted
        return;
      }

      const products = await this._productService.find({ type: productType });
      this.activatedProduct = products[0];
      if (!this.activatedProduct) {
        throw `product "${productType}" not found`
      }
      if (!this.activatedProduct.ssoRedirectUrl) {
        throw "product don't support sso login"
      }


      if (abort.isAborted) {
        // process aborted
        return;
      }

      let ssoLoginUrl = "";

      const ssoLogin = await this._authService.ssoAuthorize(
        this._authService.getToken(),
        {
          client_id: this.activatedProduct.type,
          redirect_uri: this.activatedProduct.ssoRedirectUrl,
          response_type: "code",
          scope: "read"
        }
      )
      if (!ssoLogin || !ssoLogin.url) {
        throw "invalid sso login";
      }
      ssoLoginUrl = ssoLogin.url;

      if (!ssoLoginUrl) {
        throw "invalid sso login";
      }

      if (abort.isAborted) {
        // process aborted
        return;
      }

      // open add-in in new tab and go to the previous page
      window.open(ssoLoginUrl, "_blank");
      this._location.back();

      // if (this.activatedProduct.type == "zendumaintenance") {
      //   // ZenduMA not support 3th cookies
      //   // Waiting resolution on FIIX side
      //   // Redirect page to app
      //   document.location.href = ssoLoginUrl;
      //   return;
      // }

      // redirect to location for now 
      // document.location.href = ssoLoginUrl;

      // const iframe = this.frame.nativeElement as HTMLIFrameElement;
      // iframe.src = ssoLoginUrl;
      // iframe.onload = () => {
      //   if (abort.isAborted) {
      //     // process aborted
      //     return;
      //   }
      //   this.isLoading = false;
      // }
    }
    catch (err) {
      this._notifier.error(err);
      this.isLoading = false;
    }
  }



}
