import { Component, OnInit, Input } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { ProductsService } from 'src/app/services/products.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { MatDialog } from '@angular/material';
import { BillingService } from 'src/app/services/billing.service';
import { FullscreenPreviewComponent } from './components/fullscreen-preview/fullscreen-preview.component';
import { ThemeService } from 'src/app/services/theme.service';
import { CookieService } from 'ngx-cookie-service';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: [
    './product-overview.component.scss',
    './product-overview.ghost.scss',
]
})
export class ProductOverviewComponent implements OnInit {

  public product: ZenduOne.Product;

  public isLoading: boolean;

  /**
   * Primary/selected preview
   */
  public selPreview: ZenduOne.ProductPreview;

  public isSigned: boolean;

  public isInstalled: boolean;

  public isActive: boolean;

  public requestDemoLink: string;

  public categoryName: string;

  constructor(
    private _productsService: ProductsService,
    private _categoryService: CategoryService,
    private _notify: NotifierService,
    private _activeRoute: ActivatedRoute,
    private _authService: AuthService,
    private _customerService: CustomerService,
    private _dialog: MatDialog,
    private _productService: ProductsService,
    private _billingService: BillingService,
    private _router: Router,
    private _cookies: CookieService,
    private _themeService: ThemeService
  ) {
    this.isSigned = this._authService.getUsername() ? true : false;

    const customization = this._themeService.getCustomization();
    this.requestDemoLink = customization.requestDemoLink;
  }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;
      let query = this._activeRoute.snapshot.queryParams;
      if (!query.id) {
        throw "id is empty"
      }
      this.product = await this._productsService.findOne(query.id);

      // fill category name
      await this.loadCategoryName(this.product);

      if (this.isSigned) {
        // load billing
        await this.loadBilling();
      }

      this.selPreview = this.product.content.previews[0];
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  /**
   * load product billing information
   */
  private async loadBilling() {
    let customer = await this._customerService.findOne();
    if (customer) {
      // check installation state
      this.isInstalled = false;
      let billing = await this._billingService.findOne();
      if (billing && billing.products) {
        let customerProduct = billing.products.find(p => p.id == this.product._id);
        if (customerProduct) {
          this.isInstalled = true;
          this.isActive = customerProduct.active;
        }
      }
    }
  }

  /**
   * fill category name for current product
   */
  private async loadCategoryName(product: ZenduOne.Product) {
    this.categoryName = "";
    if (!product.content ||
      !product.content.categories ||
      !product.content.categories.length) {
      return;
    }

    const categoryId = product.content.categories[0];
    const category = await this._categoryService.findOne(categoryId);
    this.categoryName = category ? category.name : "";
  }

  public isVideo(url: string) {
    return (url && (url.indexOf("youtu.be") >= 0 || url.indexOf("youtube.com") >= 0));
  }

  public async suspend() {
    try {
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Confirm",
          text: `Are you sure you want to suspend this application?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (!confirm) {
        return;
      }

      this.isLoading = true;
      await this._productService.suspend({
        productId: this.product._id
      });

      this._notify.success(`App "${this.product.name}" is suspend`);
      this.isActive = false;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public signIn() {
    if (this._cookies.get("geotab_session")) {
      this._router.navigate(["/register"])
    }
    else {
      this._router.navigate(["/sign-in"])
    }
  }

  public async expandImage(item: ZenduOne.ProductPreview) {
    if (!item || !item.preview) {
      return;
    }
    // pass only images for preview
    const items = this.product.content.previews.filter(p => !this.isVideo(p.preview));
    // open preview dialog
    await this._dialog.open(FullscreenPreviewComponent,
      {
        data: {
          items: items,
          selectedIndex: items.indexOf(item)
        },
        width: "100vw",
        height: "100vh",
        maxWidth: "100vw",
        maxHeight: "100vh",
      })
      .afterClosed().toPromise();
  }

  public scrollTo(id: string) {
    const element = document.querySelector("#" + id);
    if (element) { element.scrollIntoView(true); }
  }
}
