import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-sign-in-phone',
  templateUrl: './sign-in-phone.component.html',
  styleUrls: ['./sign-in-phone.component.scss']
})
export class SignInPhoneComponent implements OnInit {

  public phone = "";

  public backurl = "";

  public isLoading = false;

  constructor(
    private _auth: AuthService,
    private _notify: NotifierService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  public async submit() {
    try {
      this.isLoading = true;

      const phone = this.phone.trim()
        .replace(new RegExp(" ", "g"), "")
        .replace(new RegExp("-", "g"), "");

      await this._auth.requestCode(phone);

      const backurl = this._activatedRoute.snapshot.queryParams.backurl;

      this._router.navigate(["/verification-code"], { queryParams: { phone: phone, backurl: backurl } })
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
