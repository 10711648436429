import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { QueryService } from 'src/app/services/query.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _queryService: QueryService,
    private _notifier: NotifierService) { }

  ngOnInit() {
    this.autoLogin();
  }

  private async autoLogin() {
    try {
      // get token
      const zenduoneToken = this._authService.getToken()
      if (!zenduoneToken) {
        // user is not authenticated, rediret to login page
        this.redirectToLoginPage();
        return;
      }

      const queryOptions = this._queryService.getOptions();

      const oauthParams = {
        client_id: queryOptions.client_id,
        redirect_uri: queryOptions.redirect_uri,
        response_type: queryOptions.response_type,
        scope: queryOptions.scope,
        state: queryOptions.state
      }

      const data = await this._authService.ssoAuthorize(zenduoneToken, oauthParams);
      if (!data.url) {
        throw "redirect url is empty"
      }
      // redirect user back to application
      document.location.href = data.url;
    }
    catch (err) {
      this._notifier.error(err);
      this.redirectToLoginPage();
    }
  }

  private redirectToLoginPage() {
    const options = { backurl: document.location.href };
    this._router.navigate(["/sign-in"], { queryParams: options });
  }
}
