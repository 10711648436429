import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  public isLoading = false;

  public username = "";

  public password = "";

  public passwordVerify = "";

  private _token = "";

  constructor(
    private _auth: AuthService,
    private _notify: NotifierService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    try {
      this.isLoading = true;
      this._token = this._activatedRoute.snapshot.queryParams.token;
      if (!this._token) {
        throw `token is not provided`;
      }
      // verify token and get username
      const data = await this._auth.fetchAuthData(this._token);
      if (!data || !data.access) {
        throw `This link has been expired.`;
      }
      this.username = data.username;
    }
    catch (err) {
      this._notify.error(err);
      this._router.navigate(["/sign-in"]);
    }
    finally {
      this.isLoading = false;
    }
  }

  public isValidLength() {
    return this.password.length >= 8;
  }

  public isDigit() {
    return /\d/.test(this.password)
  }

  public isSymbol() {
    return /\W/.test(this.password);
  }

  public isUppercase() {
    return /[A-Z]/.test(this.password)
  }

  public isLowercase() {
    return /[a-z]/.test(this.password);
  }

  public isValidPassword() {
    return this.isValidLength() &&
      this.isDigit() &&
      this.isSymbol() &&
      this.isUppercase() &&
      this.isLowercase() &&
      this.password &&
      this.passwordVerify;
  }

  public async submit() {
    try {
      this.isLoading = true;

      if (this.password != this.passwordVerify) {
        throw `password doesn't match`;
      }

      // change the password
      await this._auth.changePassword(this.password, this._token);

      this._router.navigate(["/password-reset-success"]);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
