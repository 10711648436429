import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ZenduOne } from 'src/typings/app';
import { MarketplaceFilter } from './marketplace-filter';

@Component({
  selector: 'app-marketplace-filter',
  templateUrl: './marketplace-filter.component.html',
  styleUrls: ['./marketplace-filter.component.scss']
})
export class MarketplaceFilterComponent implements OnInit, OnChanges {

  @Input() filter: MarketplaceFilter;

  @Output() close = new EventEmitter<void>();

  public categories: Array<{ checked: boolean, name: string, id: string }> = [];

  /**
   * Is free solution visible
   */
  public freeEnabled = false;

  /**
   * Is popular solution visible
   */
  public popularEnabled = false;

  public isCategoriesExpanded = true;

  public isTypesExpanded = true;

  constructor(
    private _categoryService: CategoryService,
    private _notify: NotifierService
  ) { }

  ngOnInit() {
  }

  public ngOnChanges() {
    if (this.filter) {
      this.freeEnabled = this.filter.types.free;
      this.popularEnabled = this.filter.types.popular;

      this.updateCategories();
    }
  }

  private async updateCategories() {
    try {

      const categoriesIds = this.filter ? this.filter.categories : [];

      const categories = await this._categoryService.find();
      for (const category of categories) {
        this.categories.push({
          checked: categoriesIds.includes(category._id),
          name: category.name,
          id: category._id
        })
      }
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  /**
   * Clear options
   */
  public clearAll() {
    this.categories.filter(c => c.checked = false);
    this.freeEnabled = true;
    this.popularEnabled = true;

    this.closeDialog();
  }

  /**
   * Apply filter
   */
  public apply() {
    this.filter.categories = this.categories.filter(c => c.checked).map(c => c.id);
    this.filter.types.free = this.freeEnabled;
    this.filter.types.popular = this.popularEnabled;

    // close dialog
    this.closeDialog();
  }

  public closeDialog() {
    // close event
    this.close.emit();
  }
}
