import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ZenduOne } from 'src/typings/app';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {

  private _authData: ZenduOne.AuthData;

  private _token: string;

  constructor(
    private _http: HttpClient,
    private _cookies: CookieService) {
    this._token = this._cookies.get("auth_zenduone_marketplace");
  }

  public async forgot() {
    this._cookies.set("geotab_session", "");
    this._cookies.set("auth_zenduone_marketplace", "", 0, "/");
  }

  /**
   * Sign-in with username and password
   * @param options 
   */
  public async signInWithPassword(options: {
    username: string,
    password: string
  }) {
    let data = await this.post(`auth`, options)
    if (!data || !data.token) {
      throw "auth failed"
    }

    await this.finalize(data.token);

    // delete geotab session
    this._cookies.set("geotab_session", "");
  }

  /**
   * Sign-in with geotab session/credentials
   */
  public async signInWithGeotab(options: {
    database: string,
    username: string,
    password?: string,
    sessionId?: string,
    server: string
  }) {
    let data = await this.post(`auth-geotab`, options)
    if (!data || !data.token) {
      return false;
    }

    await this.finalize(data.token);

    // save geotab session 
    // session can be used for a new customer registration or SSO login
    this._cookies.set("geotab_session", JSON.stringify(data), new Date(Date.now() + (14 * 24 * 60 * 60000)))

    return true;
  }

  /**
   * Is the portal run inside the geotab web site
   */
  public isGeotabSession() {
    return this._cookies.get("geotab_session") ? true : false;
  }


  public async requestCode(phone: string) {
    await this._http.post(`${environment.service}/auth/requestCode`, { phone: phone }).toPromise();
  }

  public async verifyCode(phone: string, code: string) {
    let data = await this._http.post(`${environment.service}/auth/verifyCode`, { phone: phone, code: code })
      .toPromise() as { token: string };

    await this.finalize(data.token);
  }

  /** 
   * For forgotten passwords to reset the password and send email to user
   * @param email email of user that requested password change due to forgotten password
   */
  public async resetPassword(email: string) {
    return await this.post('forgotPW', email);
  }

  /**
   * Change the password for the current user
   */
  public async changePassword(password: string, token: string) {
    return await this.post(`marketplace/user/changePassword`,
      {
        password: password
      },
      {
        headers: new HttpHeaders().set("Authorization", token)
      });
  }

  /**
   * Authorize with zenduone token and oauth parameters
   * @param accessToken zenduone access token
   * @param options oauth parameters (grant code flow)
   */
  public async ssoAuthorize(
    accessToken: string,
    options: {
      client_id: string,
      redirect_uri: string,
      response_type: string,
      scope: string
    }): Promise<{ url: string }> {


    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization": accessToken
      })
    };

    const query = Object.keys(options).map(k => `${k}=${options[k]}`).join("&");
    const data = await this.post(`oauth/v2/authorize?${query}`, {}, httpOptions)
    if (!data) {
      throw "auth failed"
    }
    if (!data.url) {
      throw "redirect url is empty"
    }
    return data;
  }

  private async finalize(token: string) {
    this._token = token;
    this.saveToken(this._token);
    await this.initAuth();
  }

  public isTokenAvail(): boolean {
    return this._token ? true : false;
  }

  public async initAuth(): Promise<boolean> {
    if (!this._token) {
      return false;
    }

    this._authData = await this.fetchAuthData(this._token);
    if (!this._authData.access) {
      return false;
    }

    return true;
  }

  public async fetchAuthData(token: string): Promise<ZenduOne.AuthData> {
    return await this.post(`checkAccess`, { token: token });
  }

  /**
   * Get username for signed user
   */
  public getUsername() {
    if (!this._authData) {
      return "";
    }
    return this._authData.username;
  }

  /**
   * Get users' picture
   */
  public getUserPicture() {
    if (!this._authData) {
      return "";
    }
    return this._authData.userPicture;
  }

  /**
   * Get fullname for signed user
   */
  public getFullname() {
    if (!this._authData) {
      return "";
    }
    return this._authData.fullName;
  }


  /**
   * Get signed state
   */
  public isSigned(): boolean {
    if (!this._authData) {
      return false;
    }
    return true;
  }

  /**
   * EULA accepted by user
   */
  public isEULA(): boolean {
    if (!this._authData) {
      return false;
    }
    return this._authData.agreementAccepted || false;
  }


  public getToken() {
    return this._token;
  }

  public saveToken(token: string) {
    this._cookies.set("auth_zenduone_marketplace", token, new Date(Date.now() + (7 * 24 * 60 * 60 * 1000)), "/");
  }

  private async post(url: string, data?: any, httpOptions?: any) {
    let res = await this._http.post(
      `${environment.service}/${url}`,
      data || {},
      httpOptions || {})
      .toPromise()
    return res as any;
  }

}
