import { Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from "@angular/core";

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: "[outside-click]"
})
export class OutsideClickDirective implements OnDestroy {

    @Output() outsideClicked: EventEmitter<void> = new EventEmitter();

    constructor(private _element: ElementRef) {
        window.addEventListener("click", this.onClick.bind(this))
    }

    private onClick(evt: MouseEvent) {
        try {
            const element = this._element.nativeElement;
            if(!element){
                // element not exist
                return;
            }
            if(!element.clientWidth)
            {
                // element not visible
                return;
            }
            if (element.contains(evt.target)) {
                return;
            }
            this.outsideClicked.emit();
        }
        catch (err) {
            console.error(`OutsideClickDirective error`, err);
        }
    }

    public ngOnDestroy() {
        console.log('OutsideClickDirective destroy')
    }
}