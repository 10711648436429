import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CustomerService } from "../../services/customer.service";
import { NotifierService } from "../../services/notifier.service";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { Middleware } from "../../../typings/middleware";
import { UserService } from "../../services/user.service";
import { Subject } from "rxjs/Subject";
import { debounceTime } from "rxjs/operators";
import * as moment from 'moment';
import { Moment } from "moment";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public isLoading: boolean;

  public activeUserType: 'users' | 'drivers';

  public userOrigins = [
    { value: 'all', label: 'All Users'},
    { value: 'zenduone', label: 'ZenduOne Users'},
    { value: 'geotab', label: 'Geotab Users'},
  ];

  public activeUsersOrigin: string;

  public timeRanges = [
    {value: 'all', label: 'All Time'},
    {value: 'today', label: 'Today'},
    {value: 'yesterday', label: 'Yesterday'},
    {value: 'thisWeek', label: 'This Week'},
    {value: 'lastWeek', label: 'Last Week'},
    {value: 'lastMonth', label: 'Last Month'},
  ];

  public activeTimeRange: string;

  public availablePlatforms: string[] = [];

  private _users: Middleware.User[] = [];

  public dataSource = new MatTableDataSource<Middleware.User>([]);

  public columnsToDisplay = [
    'name',
    'firstName',
    'lastName',
    'platform',
    'type',
    'phone',
    'isMetric',
    'lastLogin'
  ];

  public filterChanged: Subject<string> = new Subject<string>();

  public filter: {
    text?: string;
    daterange?: {
      start: Moment;
      end: Moment;
    };
  } = {};

  public editUser: Middleware.User = null;

  //  Above html elements height
  private _offset = 54 + 79 + 73 + 55 + 78 + 76;
  private _rowHeight = 48;
  private _screenHeight: number;
  public pageSize = 0;
  public wrapperHeight: number;

  constructor(
    private _notify: NotifierService,
    private _customerService: CustomerService,
    private _userService: UserService
  ) {
    this.filterChanged
      .pipe(debounceTime(500))
      .subscribe(() => this.filterUsers());

    this._screenHeight = window.innerHeight
    this.calculateNumberOfRows();

    this.activeUserType = 'users';
    this.activeUsersOrigin = 'all';
    this.activeTimeRange = 'today';
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public async ngOnInit() {
    await this.getCustomerPlatforms();
    await this.refreshUsers();
  }

  ngOnDestroy() {
    this.filterChanged.unsubscribe();
  }

  calculateNumberOfRows() {
    const availableHeight = this._screenHeight - this._offset;

    this.pageSize = Math.trunc(availableHeight / this._rowHeight);
    this.wrapperHeight = 58 + (this.pageSize * this._rowHeight)
  }

  private async getCustomerPlatforms(): Promise<void> {
    try {
      this.isLoading = true;
      // load customer
      const customer = await this._customerService.findOne();
      // check geotab access by available platforms
      if (customer.platforms &&
        customer.platforms.find(c => c.id == "geotab")) {
        this.availablePlatforms.push("geotab");
      }
    } catch (err) {
      this._notify.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async refreshUsers(): Promise<void> {
    try {
      this.isLoading = true;
      const search = {
        offset: 0,
        limit: 0
        //  commented for now, should be used after full migration to the new user props
        // isDriver: this.activeUserType === 'drivers',
      };

      if (this.activeUsersOrigin !== 'all') {
        search['platform'] = this.activeUsersOrigin;
      }

      const users = await this._userService.findCustomerUsers(search);

      this._users = users.filter(el => this.activeUserType === 'drivers' ? el.isDriver : !el.isDriver);
      this.filterUsers();
    } catch (err) {
      this._notify.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public filterUsers(): void {
    const filteredUsers = this._users.filter(user => {
      const result = [];

      if (this.filter.text) {
        const searchString = this.filter.text.trim().toLowerCase();

        result.push(user.name.toLowerCase().includes(searchString));

        if (user.firstName) {
          result.push(user.firstName.toLowerCase().includes(searchString));
        }

        if (user.lastName) {
          result.push(user.lastName.toLowerCase().includes(searchString));
        }
      }

      if (this.filter.daterange && user.lastLogin) {
        result.push(moment(user.lastLogin).isBetween(this.filter.daterange.start, this.filter.daterange.end));
      }

      if(result.length === 0 ) {
        return this._users;
      }

      return result.some(el => el);
    })

    this.dataSource.data = filteredUsers;
  }

  public async handleUserTypeChanged(type: 'users' | 'drivers'): Promise<void> {
    if (this.activeUserType === type) {
      return
    }

    this.activeUserType = type;
    await this.refreshUsers();
  }

  public handleTimeRangeChanged(): void {
    switch (this.activeTimeRange) {
      case "all":
        delete this.filter.daterange;
        break;
      case "today":
        this.filter.daterange = {
          start: moment().startOf("week"),
          end: moment().endOf("week")
        }
        break;
      case "yesterday":
        this.filter.daterange = {
          start: moment().subtract(1, "day").startOf("day"),
          end: moment().subtract(1, "day").endOf("day")
        }
        break;
      case "thisWeek":
        this.filter.daterange = {
          start: moment().startOf("week"),
          end: moment().endOf("week")
        }
        break;
      case "lastWeek":
        this.filter.daterange = {
          start: moment().subtract(1, "week").startOf("week"),
          end: moment().subtract(1, "week").endOf("week")
        }
        break;
      case "lastMonth":
        this.filter.daterange = {
          start: moment().subtract(1, "month").startOf("day"),
          end: moment().endOf("day")
        }
        break;
    }

    this.filterUsers();
  }

  public async handleUserOriginChanged(): Promise<void> {
    await this.refreshUsers();
  }

  public addUser() {
    this.editUser = {
      platform: 'zenduone',
      isDriver: false,
      roles: ["customer.user"]
    } as Middleware.User;
  }

  public edit(user: Middleware.User): void {
    this.editUser = { ...user };
  }

  public async completeEdit(): Promise<void> {
    try {
      this.isLoading = true;

      const user = this.editUser;

      if (user._id) {
        // update user
        await this._userService.updateCustomerUser(user);
      } else {
        // add a new user
        await this._userService.addCustomerUser(user);
        
        this._notify.success("User successfully created");
      }

      // reset to close dialog
      this.editUser = null;

      // reload users
      await this.refreshUsers();
    } catch (err) {
      this._notify.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteUser(): Promise<void> {
    try {
      this.isLoading = true;

      await this._userService.removeCustomerUser(this.editUser._id);

      // reset to close dialog
      this.editUser = null;

      // reload users
      await this.refreshUsers();
    } catch (err) {
      this._notify.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public hideUserEdit(): void {
    this.editUser = null;
  }

  public async handleSync(type: string): Promise<void> {
    try {
      this.isLoading = true;

      switch (type) {
        case 'geotab':
          await this._userService.syncGeotabUsers();
          break;
        default:
          this._notify.error('Invalid platform name for sync');
          return;
      }

      // reload users
      await this.refreshUsers();
    } catch (err) {
      this._notify.error(err);
    } finally {
      this.isLoading = false;
    }

  }
}
