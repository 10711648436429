import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-profile-preview',
  templateUrl: './user-profile-preview.component.html',
  styleUrls: ['./user-profile-preview.component.scss']
})
export class UserProfilePreviewComponent {

  public username: string;

  public fullName: string;

  /**
   * Url to users' picture
   */
  public userPicture: string;

  constructor(
    private _auth: AuthService,
    private _router: Router,
  ) {
    this.userPicture = this._auth.getUserPicture();
    this.fullName = this._auth.getFullname();
    this.username = this._auth.getUsername();
  }


  public signOut() {
    this._auth.forgot();
    this._router.navigate(["/sign-in"])
  }

}
