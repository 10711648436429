import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable()
export class FileService {
    constructor(
        private _httpService: HttpService) {
    }


    /**
     * upload file to cloud storage
     * @param file 
     */
    public async upload(file: File): Promise<string> {
        const formData = new FormData();
        formData.append(file.name, file);

        const data = await this._httpService.post("marketplace/upload", formData);
        if (!data || !data.url) {
            throw "Uploading failed"
        }

        return data.url;
    }

}