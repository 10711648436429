import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss']
})
export class HomeMainComponent implements OnInit {

  /**
   * User firstname + lastname
   */
  public fullName: string;

  /**
   * Is started section  
   */
  public isStarterVisible = true;

  constructor(
    private _auth: AuthService
  ) {
    this.fullName = this._auth.getFullname() || "";
  }

  public ngOnInit() {
  }

  /**
   * Collapse/expand the starter section
   */
  public toggleStartSection() {
    this.isStarterVisible = !this.isStarterVisible;
  }
}
