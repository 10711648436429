import { Component, Inject } from "@angular/core";

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent {

  public isConformed: boolean;

  constructor() {

  }

}
