import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class ThemeService {

    private _customization: ZenduOne.Customization;

    constructor(private _httpService: HttpService) { }

    public async loadCustomization(resellerCode: string) {
        this._customization = await this._httpService.post(`marketplace/theme/getCustomization`, {
            resellerCode: resellerCode
        });
    }

    public getCustomization(): ZenduOne.Customization {
        return this._customization || {};
    }
}