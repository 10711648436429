import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { RegisterComponent } from './pages/register/register.component';
import { ProductOverviewComponent } from './pages/product-overview/product-overview.component';
import { InitalizeComponent } from './pages/initalize/initalize.component';
import { ProductInstallComponent } from './pages/product-install/product-install.component';
import { GeotabSigninComponent } from './pages/sign-in/geotab-signin/geotab-signin.component';
import { SupportComponent } from './pages/support/support.component';
import { TicketComponent } from './pages/ticket/ticket.component';
import { TicketThreadComponent } from './pages/ticket-thread/ticket-thread.component';
import { SsoLoginComponent } from './pages/oauth/sso-login/sso-login.component';
import { SsoProductComponent } from './pages/oauth/sso-product/sso-product.component';
import { GuideComponent } from './pages/guide/guide.component';
import { HomeMainComponent } from './pages/home/home-main/home-main.component';
import { PortalContainerComponent } from './pages/portal-container/portal-container.component';
import { MarketplaceMainComponent } from './pages/marketplace/marketplace-main/marketplace-main.component';
import { EulaComponent } from './pages/eula/eula.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { UsersComponent } from "./pages/users/users.component";
import { SignInPhoneComponent } from './pages/sign-in/sign-in-phone/sign-in-phone.component';
import { VerificationCodeComponent } from './pages/sign-in/verification-code/verification-code.component';
import { SamlLoginComponent } from './pages/saml/saml-login/saml-login.component';
import { PasswordResetComponent } from './pages/sign-in/password-reset/password-reset.component';
import { PasswordResetSuccessComponent } from './pages/sign-in/password-reset-success/password-reset-success.component';


const routes: Routes = [
    {
        path: 'portal',
        component: PortalContainerComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: "full" },
            { path: 'home', component: HomeMainComponent },
            { path: 'marketplace', component: MarketplaceMainComponent },
            { path: 'product/overview', component: ProductOverviewComponent },
            { path: 'product/install', component: ProductInstallComponent },
            { path: 'sso_product/:productType', component: SsoProductComponent },
            { path: 'support', component: SupportComponent },
            { path: 'ticket-new', component: TicketComponent },
            { path: 'ticket', component: TicketThreadComponent },
            { path: 'guide', component: GuideComponent },
            { path: 'account', component: AccountSettingsComponent },
            { path: 'users', component: UsersComponent },
        ]
    },
    { path: 'eula', component: EulaComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'saml/login', component: SamlLoginComponent },
    { path: 'oauth/sso-login', component: SsoLoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'geotab-signin', component: GeotabSigninComponent },
    { path: 'phone-signin', component: SignInPhoneComponent },
    { path: 'password-reset', component:  PasswordResetComponent },
    { path: 'password-reset-success', component:  PasswordResetSuccessComponent },
    { path: 'verification-code', component: VerificationCodeComponent },
    { path: '**', component: InitalizeComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
