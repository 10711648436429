import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Middleware } from "src/typings/middleware";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class CustomerService {
    constructor(private _httpService: HttpService) { }

    public async add(opts: {
        customer: ZenduOne.Customer,
        resellerCode: string,
        creditCardToken: string,
        platforms: ZenduOne.PlatformOptions[]
    }):
        Promise<{ id: string, tokenId: string }> {
        return await this._httpService.post("marketplace/customers/add", opts);
    }

    /**
     * Get current customer
     */
    public async findOne(): Promise<ZenduOne.Customer> {
        return await this._httpService.post("marketplace/customers/findOne");
    }

    public async getUsers(search: {
        type: string
    }): Promise<Array<Middleware.User>> {
        return await this._httpService.post("marketplace/customers/getUsers", search);
    }

    public async getVehicles(search: {
        type: string
    }): Promise<Array<Middleware.Device>> {
        return await this._httpService.post("marketplace/customers/getVehicles", search);
    }

    /**
     * Accept ELUA
     */
    public async acceptAgreement() {
        await this._httpService.post("marketplace/user/acceptAgreement");
    }

    
}