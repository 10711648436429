import { Injectable } from '@angular/core';

@Injectable()
export class QueryService {

    constructor() { }

    public getOptions(): { [key: string]: string } {
        // This function is anonymous, is executed immediately and 
        // the return value is assigned to QueryString!
        const query_string = {};
        let query = "";
        if (window.location.hash) {
            let idx = window.location.hash.indexOf("?");
            if (idx >= 0) {
                query = window.location.hash.substr(idx + 1);
            }
        }
        else if (window.location.search) {
            query = window.location.search.substring(1);
        }
        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = decodeURIComponent(pair[1]);
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
                let arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
                query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
                query_string[pair[0]].push(decodeURIComponent(pair[1]));
            }
        }
        return query_string;
    }
}
