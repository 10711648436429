import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';
import { ZenduOne } from 'src/typings/app';

@Component({
  selector: 'app-home-apps',
  templateUrl: './home-apps.component.html',
  styleUrls: ['./home-apps.component.scss']
})
export class HomeAppsComponent implements OnInit {

  /**
   * loading indicator
   */
  public isLoading = false;

  /**
   * installed products
   */
  public ssoProducts: ZenduOne.Product[] = [];

  /**
   * Geotab is available 
   */
  public isGeotabAvail = false;

  /**
   * Flag for users with no content
   */
  public isNoData = false;

  constructor(
    private _auth: AuthService,
    private _productsService: ProductsService,
    private _customerService: CustomerService,
    private _notify: NotifierService) { }

  ngOnInit() {
    this.init();
  }

  /**
   * Load installed application for current user
   */
  private async init() {
    try {
      this.isLoading = true;

      // load apps that installed and supported Single-Sign-On
      await this.loadSSOProducts();

      // check geotab access
      await this.checkGeotabAccess();

      if (!this.ssoProducts.length && !this.isGeotabAvail) {
        // mark as no data
        this.isNoData = true;
      }

    } catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }

  }

  /**
   * check geotab access
   */
  private async checkGeotabAccess() {
    if (!this._auth.isSigned()) {
      // don't show geotab widget for not signed user
      this.isGeotabAvail = false;
      return;
    }
    if (this._auth.isGeotabSession()) {
      // Don't show Geotab widget when user open Zenduone inside the Geotab portal
      this.isGeotabAvail = false;
      return;
    }

    // load customer
    const customer = await this._customerService.findOne();
    // check geotab access by available platforms
    if (customer.platforms &&
      customer.platforms.find(c => c.id == "geotab")) {
      this.isGeotabAvail = true;
    }
  }

  /**
   * Load apps that installed and supported Single-Sign-On
   */
  private async loadSSOProducts() {
    this.ssoProducts = [];

    if (!this._auth.isSigned()) {
      return;
    }

    const username = this._auth.getUsername();
    this.ssoProducts = await this._productsService.findInstalled({
      username: username
    });
  }

  /**
   * redirect to geotab
   */
  public signInGeotab() {
    window.open(`${environment.service}/saml/geotab/redirect?token=${this._auth.getToken()}`, "_blank")
  }
}
