import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-starter',
  templateUrl: './marketplace-starter.component.html',
  styleUrls: ['./marketplace-starter.component.scss']
})
export class MarketplaceStarterComponent implements OnInit {

 
  /**
   * Categories
   */
  public categories: Array<{ title: string, id: string }> = [];

  /**
   * Category items
   */
  public items: Array<{ preview: string, title: string, duration: number }> = [];

  constructor() {
    this.createCategories();

    this.createItems();
  }

  private createCategories() {
    this.categories = [
      { id: "basics", title: "Basics" },
      { id: "howto", title: "How marketplace work" },
      { id: "new", title: "New to ZenduOne" }
    ];
  }

  private createItems() {
    this.items = [
      {
        preview: "/assets/img/home-starter/preview1.png",
        title: "Get the most out of ZenduMarketplace",
        duration: 3
      },
      {
        preview: "/assets/img/home-starter/preview2.png",
        title: "What is marketplace?",
        duration: 6
      },
      {
        preview: "/assets/img/home-starter/preview2.png",
        title: "See how you can sell your solution here",
        duration: 9
      }
    ];
  }

  ngOnInit() {
  }

}
