import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { RouteBillingCustomer } from "src/typings/billing";
import { ProductBilling } from "src/typings/product.billing";


@Injectable()
export class ProductBillingService {
    constructor(private _httpService: HttpService) { }

    public async findOne(productId: string): Promise<ProductBilling> {
        const data = await this._httpService.post("marketplace/billing/product/get", {
            productId: productId
        });
        return data;
    }
}