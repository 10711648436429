import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class VehicleService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.Vehicle> {
        let items = await this._httpService.post("marketplace/vehicles/find", { id: id });
        return items[0];
    }

    public async find(): Promise<Array<ZenduOne.Vehicle>> {
        let items = await this._httpService.post("marketplace/vehicles/find", {});
        return items;
    }
}