import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { QueryService } from 'src/app/services/query.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-saml-login',
  templateUrl: './saml-login.component.html',
  styleUrls: ['./saml-login.component.scss']
})
export class SamlLoginComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _queryService: QueryService,
    private _notifier: NotifierService) { }

  ngOnInit() {
    this.autoLogin();
  }

  private async autoLogin() {
    const options = this._queryService.getOptions();

    try {
      // get token
      const zenduoneToken = this._authService.getToken();
      if (!zenduoneToken) {
        // redirect to login page
        this.redirectToLoginPage();
        return;
      }

      // verify token
      await this._authService.initAuth()

      // redirect user to SAML callback
      document.location.href = `${environment.service}/saml/login?` +
        `RelayState=${options.RelayState || ""}&` +
        `SAMLRequest=${encodeURIComponent(options.SAMLRequest || "")}&` +
        `token=${zenduoneToken}`;
    }
    catch (err) {
      this._notifier.error(err);
      this.redirectToLoginPage();
    }
  }

  private redirectToLoginPage() {
    console.log(`Redirect to login page with SAMLRequest`);
    const options = { backurl: document.location.href };
    this._router.navigate(["/sign-in"], { queryParams: options });
  }
}
