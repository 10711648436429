import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { FileService } from 'src/app/services/file.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { UserService } from 'src/app/services/user.service';
import { ZenduOne } from 'src/typings/app';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  /**
   * Loading page indicator
   */
  public isLoading = false;

  /**
   * Photo uploading indicator
   */
  public isUploading = false;

  public user: ZenduOne.User = null;

  constructor(
    private _userService: UserService,
    private _fileService: FileService,
    private _notify: NotifierService,
    private _dialog: MatDialog
  ) {
    this.init();
  }

  ngOnInit() {
  }

  private async init() {
    try {
      this.isLoading = true;

      this.user = await this._userService.getCurrent();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }


  /**
   * handle logo selection
   */
  public async handleFileInput(files: FileList) {
    try {
      if (!files.length) {
        return;
      }

      this.isUploading = true;

      const file = files.item(0);
      this.user.photo = await this._fileService.upload(file);
      // save settings
      await this._userService.update(this.user);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isUploading = false;
    }
  }

  /**
   * save profile
   */
  public async save() {
    try {
      this.isLoading = true;

      await this._userService.update(this.user);

      this._notify.success("Settings successfully saved");
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async resetPassword() {
    try {
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Password Reset",
          text: `Are you sure you want to reset your password?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (!confirm) {
        return;
      }
      
      this.isLoading = true;

      await this._userService.resetPassword();

      this._notify.success("Check your inbox for a new password");
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

}
