import {
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatSidenavModule,
    MatRippleModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatListModule,
    MatDividerModule,
    MatCardModule, 
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatTooltipModule,
    MatProgressBarModule
} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
      MatButtonModule,
      MatCheckboxModule,
      MatInputModule,
      MatFormFieldModule,
      MatTabsModule,
      MatTableModule,
      MatSortModule,
      MatSidenavModule,
      MatMenuModule,
      MatIconModule,
      MatRippleModule,
      MatDialogModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatAutocompleteModule,
      MatSelectModule,
      MatListModule,
      MatDividerModule,
      MatCardModule,
      MatSnackBarModule,
      MatProgressSpinnerModule,
      MatGridListModule,
      MatTooltipModule,
      MatProgressBarModule
  ],
  exports: [
      MatButtonModule,
      MatCheckboxModule,
      MatInputModule,
      MatFormFieldModule,
      MatTabsModule,
      MatTableModule,
      MatSortModule,
      MatSidenavModule,
      MatMenuModule,
      MatIconModule,
      MatRippleModule,
      MatDialogModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatAutocompleteModule,
      MatSelectModule,
      MatListModule,
      MatDividerModule,
      MatCardModule,
      MatSnackBarModule,
      MatProgressSpinnerModule,
      MatGridListModule,
      MatTooltipModule,
      MatProgressBarModule
  ],
})
export class AppMaterialModule { }
