export class MemoryService {
    private static data = {};

    public setValue(key: string, value: Object) {
        // save reseller id in the memory
        MemoryService.data[key] = value;
    }

    public getValue(key: string) {
        return  MemoryService.data[key];
    }
}