import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ticketSubject'
})
export class TicketSubjectPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (!value) {
            return "None";
        }
        return value
            .replace("Zenduone:", "")
            .trim();
    }

}
