import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'cancel-confirm-dialog',
    templateUrl: './cancel-confirm.component.html',
    styleUrls: ['./cancel-confirm.component.scss']
})
export class CancelConfirmComponent {

    public title: string;

    public text: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: {
            title: string,
            text: string
        }) {
            this.title = data.title;
            this.text = data.text;
    }

}