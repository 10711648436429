import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";

@Injectable()
export class CategoryService {

    /**
     * cached categories
     */
    private _categories: ZenduOne.Category[];
    
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.Category> {
        if(this._categories){
            // use cache
            return this._categories.find(c => c._id == id);
        }

        return await this._httpService.post(`marketplace/products_categories/findOne`, {
            id: id
        });
    }

    public async find(): Promise<Array<ZenduOne.Category>> {
        if(this._categories){
            // use cache
            return this._categories;
        }

        this._categories = await this._httpService.post(`marketplace/products_categories/find`);
        return this._categories;
    }

}