import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable()
export class SupportService {
    constructor(private _httpService: HttpService) { }

    public async findTickets(): Promise<Ticket[]> {
        return await this._httpService.post(`marketplace/support/tickets/find`, {});
    }

    public async findThreads(ticketId: string): Promise<Ticket[]> {
        return await this._httpService.post(`marketplace/support/tickets/threads`, { ticketId: ticketId });
    }

    public async createTicket(ticket: Ticket): Promise<{ id: string }> {
        return await this._httpService.post(`marketplace/support/tickets/add`, ticket);
    }

    public async updateTicket(ticket: Ticket) {
        await this._httpService.post(`marketplace/support/tickets/update`, ticket);
    }

    public async sendReply(options: {
        ticketId: string, text: string
    }) {
        await this._httpService.post(`marketplace/support/tickets/sendReply`, options);
    }
}