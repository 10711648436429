import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ImageUploadModule } from "angular2-image-upload";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppMaterialModule } from './app.material';

import { QueryService } from './services/query.service';
import { NotifierService } from './services/notifier.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HtmlTooltip } from './components/tooltip/htmlTooltip';
import { Daterangepicker } from 'ng2-daterangepicker';
import { HttpService } from './services/http.service';
import { HtmlTooltipContent } from './components/tooltip/htmlTooltipContent';
import { AppService } from './services/app.service';
import { MatGridListModule, MatListModule, MatToolbarModule, MatTabsModule, MatPaginatorModule, MatSlideToggleModule } from '@angular/material';
import { ProductComponent } from './pages/products/components/product/product.component';
import { AppNavbarComponent } from './components/app-navbar/app-navbar.component';
import { ProductsService } from './services/products.service';
import { ProductBillingService } from './services/product.billing.service';
import { ProductOverviewComponent } from './pages/product-overview/product-overview.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SafePipe } from './pipes/safe.pipe';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthService } from './services/auth.service';
import { RegisterComponent } from './pages/register/register.component';
import { ProductsListComponent } from './pages/products-list/products-list.component';
import { CategoryService } from './services/category.service';
import { CustomerService } from './services/customer.service';
import { InitalizeComponent } from './pages/initalize/initalize.component';
import { ProductInstallComponent } from './pages/product-install/product-install.component';
import { VehicleService } from './services/vehicle.service';
import { CancelConfirmComponent } from './dialogs/cancel-confirm/cancel-confirm.component';
import { BillingService } from './services/billing.service';
import { VechileTypeLabelPipe } from './pipes/vehicle.type.label.pipe';
import { GeotabSigninComponent } from './pages/sign-in/geotab-signin/geotab-signin.component';
import { MemoryService } from './services/memory.service';
import { SupportComponent } from './pages/support/support.component';
import { SupportService } from './services/support.service';
import { TicketComponent } from './pages/ticket/ticket.component';
import { TicketThreadComponent } from './pages/ticket-thread/ticket-thread.component';
import { FullscreenPreviewComponent } from './pages/product-overview/components/fullscreen-preview/fullscreen-preview.component';
import { ThemeService } from './services/theme.service';
import { SsoLoginComponent } from './pages/oauth/sso-login/sso-login.component';
import { SsoProductComponent } from './pages/oauth/sso-product/sso-product.component';
import { UserAgreementComponent } from './pages/product-install/components/user-agreement/user-agreement.component';
import { InstallFinalizeComponent } from './pages/product-install/components/install-finalize/install-finalize.component';
import { GuideComponent } from './pages/guide/guide.component';
import { TicketSubjectPipe } from './pipes/ticket.subject.pipe';
import { PopupVideoComponent } from './components/popup-video/popup-video.component';
import { ForgotComponent } from './pages/sign-in/forgot/forgot.component';
import { HomeAppsComponent } from './pages/home/home-apps/home-apps.component';
import { HomeAdminComponent } from './pages/home/home-admin/home-admin.component';
import { HomeItemComponent } from './pages/home/home-item/home-item.component';
import { PortalContainerComponent } from './pages/portal-container/portal-container.component';
import { HomeMainComponent } from './pages/home/home-main/home-main.component';
import { HomeStarterComponent } from './pages/home/home-starter/home-starter.component';
import { HomeMarketplaceComponent } from './pages/home/home-marketplace/home-marketplace.component';
import { MarketplaceMainComponent } from './pages/marketplace/marketplace-main/marketplace-main.component';
import { MarketplaceStarterComponent } from './pages/marketplace/marketplace-starter/marketplace-starter.component';
import { EulaComponent } from './pages/eula/eula.component';
import { UserProfilePreviewComponent } from './components/user-profile-preview/user-profile-preview.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { FileService } from './services/file.service';
import { UserService } from './services/user.service';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { OutsideClickDirective } from './directives/outside-click';
import { ClickStopDirective } from './directives/click-stop-propagation';
import { MarketplaceFilterComponent } from './pages/marketplace/marketplace-filter/marketplace-filter.component';
import { UsersComponent } from "./pages/users/users.component";
import { UserEditComponent } from "./pages/users/components/user-edit/user-edit.component";
import { DeleteUserComponent } from "./dialogs/delete-user/delete-user.component";
import { SignInPhoneComponent } from './pages/sign-in/sign-in-phone/sign-in-phone.component';
import { VerificationCodeComponent } from './pages/sign-in/verification-code/verification-code.component';
import { SamlLoginComponent } from './pages/saml/saml-login/saml-login.component';
import { PasswordResetComponent } from './pages/sign-in/password-reset/password-reset.component';
import { PasswordResetSuccessComponent } from './pages/sign-in/password-reset-success/password-reset-success.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppRoutingModule,
        AppMaterialModule,
        FlexLayoutModule,
        NgMultiSelectDropDownModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        Daterangepicker,
        ImageUploadModule.forRoot(),
        DragDropModule,
        MatGridListModule,
        MatListModule,
        MatToolbarModule,
        MatTabsModule,
        MatPaginatorModule,
        MatSlideToggleModule
    ],
    declarations: [
        AppComponent,
        HtmlTooltip,
        HtmlTooltipContent,
        ProductComponent,
        AppNavbarComponent,
        ProductOverviewComponent,
        CarouselComponent,
        SafePipe,
        VechileTypeLabelPipe,
        TicketSubjectPipe,
        SignInComponent,
        RegisterComponent,
        ProductsListComponent,
        InitalizeComponent,
        ProductInstallComponent,
        CancelConfirmComponent,
        DeleteUserComponent,
        GeotabSigninComponent,
        SupportComponent,
        TicketComponent,
        TicketThreadComponent,
        FullscreenPreviewComponent,
        SsoLoginComponent,
        SsoProductComponent,
        UserAgreementComponent,
        InstallFinalizeComponent,
        GuideComponent,
        PopupVideoComponent,
        ForgotComponent,
        HomeAppsComponent,
        HomeAdminComponent,
        HomeItemComponent,
        HomeMainComponent,
        PortalContainerComponent,
        HomeStarterComponent,
        HomeMarketplaceComponent,
        MarketplaceMainComponent,
        MarketplaceStarterComponent,
        EulaComponent,
        UserProfilePreviewComponent,
        AccountSettingsComponent,
        AppMenuComponent,
        OutsideClickDirective,
        ClickStopDirective,
        MarketplaceFilterComponent,
        UsersComponent,
        UserEditComponent,
        SignInPhoneComponent,
        VerificationCodeComponent,
        SamlLoginComponent,
        PasswordResetComponent,
        PasswordResetSuccessComponent
    ],
    providers: [
        QueryService,
        NotifierService,
        ProductsService,
        CookieService,
        HttpService,
        AppService,
        AuthService,
        CategoryService,
        CustomerService,
        VehicleService,
        BillingService,
        MemoryService,
        SupportService,
        ThemeService,
        ProductBillingService,
        FileService,
        UserService
    ],
    entryComponents: [
        CancelConfirmComponent,
        DeleteUserComponent,
        FullscreenPreviewComponent,
        ForgotComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
