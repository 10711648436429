import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ZenduOne } from "src/typings/app";
import { Middleware } from "src/typings/middleware";

@Injectable()
export class ProductsService {
    constructor(private _httpService: HttpService) { }

    public async findOne(id: string): Promise<ZenduOne.Product> {
        return await this._httpService.post(`marketplace/products/findOne`, {
            id: id
        });
    }

    public async find(search: {
        name?: string,
        type?: string,
        ids?: Array<string>,
        category?: string
    }): Promise<Array<ZenduOne.Product>> {
        let items: Array<ZenduOne.Product> = await this._httpService.post(`marketplace/products/find`, search);
        // hide draft productsa
        items = items.filter(i => i.status != "draft")
        return items;
    }

    /**
     * Get installed application for specific user
     */
    public async findInstalled(search: {
        username: string
    }): Promise<ZenduOne.Product[]> {
        let products = await this._httpService.post(`marketplace/products/installed/find`, search);

        // show only products that published and support standalone sso
        products = products.filter(s =>
            s.status == "published" &&
            s.ssoRedirectUrl &&
            !s.ssoRedirectUrl.includes("{database}"));
            
        return products;
    }

    public async install(options: {
        productId: string,
        users?: Array<Middleware.ConfigureUser>,
        vehicles?: Array<Middleware.ConfigureVehicle>,
        billingPlan?: string
    }) {
        return await this._httpService.post(`marketplace/installer/install`, options);
    }

    public async suspend(options: { productId: string }) {
        return await this._httpService.post(`marketplace/installer/suspend`, options);
    }


}
