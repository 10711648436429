import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'src/app/services/notifier.service';
import { AuthService } from 'src/app/services/auth.service';
import { QueryService } from 'src/app/services/query.service';
import { MatDialog } from '@angular/material';
import { ForgotComponent } from './forgot/forgot.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public isLoading: boolean;

  public username: string;

  public password: string;

  public backurl = "";

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _notifier: NotifierService,
    private _queryService: QueryService,
    private _dialog: MatDialog) { }

  ngOnInit() {
    const options = this._queryService.getOptions();
    this.backurl = options.backurl;
  }

  public async signIn(data) {
    try {
      this.isLoading = true;

      const credentials = {
        username: data.value.username,
        password: data.value.password
      };
      await this._authService.signInWithPassword(credentials);

      if (!this._authService.isEULA()) {
        // navigate to elua accepting
        this._router.navigate(['/eula']);
        return;
      }

      this.redirectToSite();
    }
    catch (err) {
      this._notifier.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async forgotPw(e) {
    try {
      e.preventDefault();
      let dialogRef = this._dialog.open(ForgotComponent,
        {
          width: '300px',
          height: '275px'
        })
      dialogRef.afterClosed().subscribe(async (res) => {
        if (!res) {
          return;
        }
        this._authService.resetPassword(res).then(() => {
          this._notifier.success("Password reset. Please go to your email to find your new password");

        }, (err) => {
          this._notifier.error("Unable to reset password", err)
          return; // Don't need both this and the catch block to notify errors
        });

      })
    } catch (err) {
      this._notifier.error(err);
    } finally {
    }
  }

  private redirectToSite() {
    const options = this._queryService.getOptions();
    if (options["backurl"]) {
      // redirect to backurl
      document.location.href = decodeURIComponent(options["backurl"]);
      return;
    }

    this._router.navigate(["/portal/home"]);
  }

}
