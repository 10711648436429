import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iif } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss']
})
export class VerificationCodeComponent implements OnInit {

  public phone = "";

  public backurl = "";

  public code = "";

  public isLoading = false;

  constructor(
    private _auth: AuthService,
    private _notify: NotifierService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.phone = this._activatedRoute.snapshot.queryParams.phone;
    if (!this.phone) {
      this._notify.error(`Phone number not passed`);
    }
    this.backurl = this._activatedRoute.snapshot.queryParams.backurl;
  }

  public async submit() {
    try {
      this.isLoading = true;

      if (!this.code) {
        throw `Code is empty`;
      }

      await this._auth.verifyCode(this.phone, this.code);

      const backurl = this._activatedRoute.snapshot.queryParams.backurl;
      if (backurl) {
        // redirect to backurl
        document.location.href = decodeURIComponent(backurl);
        return;
      }

      this._router.navigate(["/portal/home"]);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async requestCode() {
    try {
      this.isLoading = true;

      await this._auth.requestCode(this.phone);

      this._notify.info(`New code was sent to you`);
    }
    catch (err) {
      this._notify.error(err);
    }
  }

}
