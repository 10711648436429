import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';


/*
  *
  * @param() items - List of items to belong in carousel
  * @param() width - Size of window(view) to show
  * @param() $prev - Template for previous button
  * @param() $next - Template for next button
  * @param() $item - Template for the item
*/
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {

  @Input() items = [];

  @ViewChild('list') list: ElementRef;

  @Input() set activeIndex(item: number) {
    this.childIndex = item;
    this.amount = -(this.calcScroll(this.list.nativeElement));
  };

  @Output() activeIndexChange = new EventEmitter();

  @Input() width = 500;

  @Input() $prev;

  @Input() $next;

  @Input() $item;

  @Output() onSelectedItem: EventEmitter<any> = new EventEmitter();

  public childIndex: number = 0;

  public amount: number = 0;

  public startPress: number = 0;

  public lastX: number = 0;

  public onMousedown(e: MouseEvent) {
    if (e.which === 1) {
      this.startPress = e.clientX;
      this.lastX = this.amount;
    }
  }
  public onTouchdown(e: TouchEvent) {
    if (navigator.userAgent.indexOf('Android') >= 0) e.preventDefault();
    this.startPress = e.targetTouches[0].clientX;
    this.lastX = this.amount;
  }

  public onMousemove(e: MouseEvent, maxWidth: number) {
    if (e.which === 1) {
      const amount = this.lastX - (this.startPress - e.clientX);
      if (amount > 0 || amount < -(maxWidth - this.width)) return;
      this.amount = amount;
    }
  }
  public onTouchmove(e: TouchEvent, maxWidth: number) {
    if (navigator.userAgent.indexOf('Android') >= 0) e.preventDefault();
    const amount = this.lastX - (this.startPress - e.targetTouches[0].clientX);
    if (amount > 0 || amount < -(maxWidth - this.width)) return;
    this.amount = amount;
  }

  public onMouseup(e: MouseEvent, elem) {
    if (e.which === 1) {
      this.startPress = 0;
      this.snap(elem);
    }
  }

  public onTouchup(e: TouchEvent, elem) {
    if (navigator.userAgent.indexOf('Android') >= 0) e.preventDefault();
    this.startPress = 0;
    this.snap(elem);
  }

  public snap(elem) {
    let counter = 0;
    let lastVal = 0;
    for (let i = 0; i < this.items.length; i++) {
      const el = elem.children[i];
      const style = el.currentStyle || window.getComputedStyle(el);
      counter += el.offsetWidth + (parseFloat(style.marginLeft) + parseFloat(style.marginRight));
      if (this.amount <= lastVal && this.amount >= -counter) {
        this.amount = -lastVal;
        this.childIndex = i;
        this.onSelectedItem.emit({ item: this.items[this.childIndex], index: this.childIndex });
        this.activeIndexChange.emit(this.childIndex); 
        return;
      }
      lastVal = counter;
    }
    return counter;
  }

  public scroll(forward, elem) {
    this.childIndex += forward ? 1 : -1;
    this.onSelectedItem.emit({ item: this.items[this.childIndex], index: this.childIndex });
    this.activeIndexChange.emit(this.childIndex); 
    this.amount = -(this.calcScroll(elem));
  }

  public calcScroll(elem) {
    let counter = 0;
    for (let i = this.childIndex - 1; i >= 0; i--) {
      const el = elem.children[i];
      const style = el.currentStyle || window.getComputedStyle(el);
      counter += el.offsetWidth + (parseFloat(style.marginLeft) + parseFloat(style.marginRight));
    }
    return counter;
  }

  public ngOnChanges(changes) {
    if (changes.items && changes.items.previousValue != changes.items.currentValue) {
      this.amount = 0;
    }
  }
}
