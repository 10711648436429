import { Component, Input, OnChanges } from '@angular/core';
import { ZenduOne } from 'src/typings/app';
import { RouteBillingProduct } from 'src/typings/billing';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnChanges {

  @Input() product: ZenduOne.Product;

  @Input() billing: RouteBillingProduct;

  public tags: Array<{ icon: string }> = [];

  public description: string;

  constructor() {
  }

  ngOnChanges() {
    if (!this.product) {
      return;
    }

    // setup description
    this.description = this.product.content.shortDescription || "";
    if (this.description.length > 150) {
      this.description = this.description.slice(0, 150) + "...";
    }

    this.updateTags();
  }

  private async updateTags() {
    this.tags = [];
    if (this.product.platforms.find(p => p.id == "geotab")) {
      this.tags.push({ icon: "/assets/img/geotab.png" })
    }
  }


}
